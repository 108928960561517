// Import react and router DOM
import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";

//Import components
import PriceTable from "./priceTable/PriceTable.jsx";
import BagDetailsForm from "./BagDetailsForm/BagDetailsForm.jsx";
import ProductionDetailsForm from "./productionDetailsForm/ProductionDetailsForm.jsx";
import ExtrusionDetailsForm from "./extrusionDetailsForm/ExtrusionDetailsForm.jsx";
import ResultsForm from "./resultsForm/ResultsForm.jsx";
import PrintDetailsForm from "./printDetailsForm/PrintDetailsForm.jsx";
import DeleteModal from "../productionSheetsTable/productionSheetTableRow/modals/deleteModal.jsx";
import CopyModal from "../productionSheetsTable/productionSheetTableRow/modals/copyModal.jsx";
import MaterialForm from "./materialForm/MaterialForm.jsx";
import InformationForm from "./information/InformationForm.jsx";

import PackingDetailsFormTest from "./packingDetailsForm/packingDetailsFormTest.jsx";
import PackingDetailsForm from "./packingDetailsForm/packingDetailsForm.jsx";
import AccessoriesForm from "./accessoriesForm/AccessoriesForm.jsx";

// Import empty form
import { emptyProductionSheet } from "../../data/emptyForms.jsx";

//Import states, contexts and CSS
import { Container, Form, Row, Button, Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import styles from "./ProductionSheetForm.module.scss";
import productionSheetContext from "../../store/productionSheetContext";
import { useActiveUser } from "../../store/activeUserContext.jsx";
import {
  PlusCircle,
  Files,
  Trash3,
  Save,
  ArrowLeftCircle,
} from "react-bootstrap-icons";

import { notifySuccess, notifyError } from "../toastify/toastify";
import { SpinnerButton } from "../../utils/spinners.jsx";

const ProductionSheetForm = ({ productionSheetId }) => {
  // Altri const
  const { activeUser } = useActiveUser();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingConfirm, setIsLoadingConfirm] = useState(false);

  const initializeProductionSheet = (empty) => ({
    ...emptyProductionSheet,
    userId: activeUser._id,
  });

  const [newProductionSheet, setNewProductionSheet] = useState(
    initializeProductionSheet()
  );

  // Formattazione data e ora
  const formatDate = (date, includeTime = true) => {
    if (!date) return "";
    const options = includeTime
      ? {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        } // Rimuoviamo i secondi
      : { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Intl.DateTimeFormat("en-GB", options).format(new Date(date));
  };

  // Copy Modal
  const [showCopyModal, setShowCopyModal] = useState(false);
  const handleCloseCopyModal = () => setShowCopyModal(false);
  const handleShowCopyModal = () => setShowCopyModal(true);

  // Delete Modal
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleDeleteShowModal = () => setShowDeleteModal(true);
  const handleDeleteCloseModal = () => setShowDeleteModal(false);

  const handleCopy = () => {
    handleShowCopyModal();
  };
  const token = localStorage.getItem("token");

  const handleConfirmDelete = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/productionSheets/${productionSheetId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      // Close the modal
      handleDeleteCloseModal();
      notifySuccess("Production sheet deleted successfully");
      navigate("/dashboard/productionSheetList");
    } catch (error) {
      notifyError("Error during production sheet deletion");
      handleDeleteCloseModal();
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = () => {
    // Show the confirmation modal
    handleDeleteShowModal();
  };

  const handleConfirmCopy = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/productionSheets/${productionSheetId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const copySheet = await response.json();

      // Rimuovi le proprietà indesiderate
      const { _id, createdAt, updatedAt, __v, ...copySheetData } = copySheet;

      try {
        const postResponse = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/api/productionSheets/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(copySheetData),
          }
        );

        if (!postResponse.ok) {
          throw new Error(postResponse.statusText);
        }

        const newSheet = await postResponse.json();
        const newSheetId = newSheet._id;
        localStorage.setItem("editProductionSheetId", newSheetId);
        notifySuccess("Production sheet copied successfully");
      } catch (error) {
        notifyError("Error during production sheet copy");
      }
    } catch (error) {
      notifyError("Error during production sheet copy");
    } finally {
      setIsLoading(false);
    }
  };

  /* eslint-disable react-hooks/exhaustive-deps */

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (productionSheetId) {
          const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/api/productionSheets/${productionSheetId}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          if (!response.ok) {
            throw new Error(response.statusText);
          }
          const data = await response.json();
          setNewProductionSheet(data);
        } else {
          setNewProductionSheet(initializeProductionSheet());
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [productionSheetId]);

  //Funzione per assegnare i valori degli input all'oggetto emptyProductionSheet

  const submitNewProductionSheet = async (e) => {
    e.preventDefault();
    setIsLoadingConfirm(true);

    // se il campo _id è configurato faccio la put senno faccio la post
    if (newProductionSheet._id) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/api/productionSheets/${newProductionSheet._id}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(newProductionSheet),
          }
        );
        if (!response.ok) {
          throw new Error(response.statusText);
        }

        localStorage.removeItem("editProductionSheetId");
        navigate(`/dashboard/productionSheetList`);
        notifySuccess("Production sheet updated successfully");
        alert("Production sheet saved successfully");
      } catch (error) {
        notifyError("Error during production sheet update");
        alert("Error during production sheet update");
      } finally {
        setIsLoadingConfirm(false);
      }
    } else {
      try {
        // Send request to the server
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/api/productionSheets`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },

            body: JSON.stringify(newProductionSheet),
          }
        );

        if (response.ok) {
          notifySuccess("Production sheet created successfully");
        }

        navigate(`/dashboard/productionSheetList`);
      } catch (error) {
        notifyError("Error during production sheet creation");
      } finally {
        setIsLoadingConfirm(false);
      }
    }
  };

  const discardAction = () => {
    setNewProductionSheet(initializeProductionSheet());
    // Ripulisci anche il campo Production Sheet Id
    setNewProductionSheet((prevState) => ({ ...prevState, _id: undefined }));
    navigate(`/dashboard/productionSheetList`);
    localStorage.removeItem("editProductionSheetId");
  };

  const newAction = () => {
    setNewProductionSheet(initializeProductionSheet());
    setNewProductionSheet((prevState) => ({ ...prevState, _id: undefined }));
    navigate(`/dashboard/productionSheetPage`);
    localStorage.removeItem("editProductionSheetId");
  };

  return (
    <>
      <productionSheetContext.Provider
        value={{ newProductionSheet, setNewProductionSheet }}
      >
        <Container className={"mb-4"}></Container>
        {/* <Container className={styles["production-sheet-form-container"]}> */}
        <Form>
          <Row>
            <Col xs={12} lg={12}>
              <Row>
                <Col xs={12} lg={4}>
                  {/* Dati production sheet e utente */}
                  <Card className={"mb-5"}>
                    <Card.Header>
                      {" "}
                      <Card.Title>ID's</Card.Title>
                    </Card.Header>

                    <Card.Body>
                      <Form.Group
                        controlId="productionSheetId"
                        className={"mb-2"}
                      >
                        <Form.Label>Production Sheet Id</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Production Sheet Id"
                          name="productionSheetId"
                          disabled
                          readOnly
                          value={newProductionSheet._id || ""} // Utilizza una stringa vuota se _id è undefined
                        />
                      </Form.Group>
                      <Form.Group controlId="userId" className={"mb-2"}>
                        <Form.Label>User Id</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="User Id"
                          name="userId"
                          disabled
                          readOnly
                          value={activeUser._id}
                        ></Form.Control>
                      </Form.Group>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xs={12} lg={4}>
                  {/* Dati production sheet e utente */}
                  <Card className={"mb-5"}>
                    <Card.Header>
                      {" "}
                      <Card.Title>Dates</Card.Title>
                    </Card.Header>

                    <Card.Body>
                      <Form.Group controlId="createdAt" className={"mb-2"}>
                        <Form.Label>Created</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder={formatDate(
                            new Date().toISOString(),
                            false
                          )} // Solo la data come placeholder
                          name="createdAt"
                          disabled
                          readOnly
                          value={formatDate(newProductionSheet.createdAt) || ""}
                        />
                      </Form.Group>

                      {newProductionSheet.updatedAt && ( // Mostra solo se updatedAt è presente
                        <Form.Group controlId="updatedAt" className={"mb-2"}>
                          <Form.Label>Last Update</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="No updates yet" // Placeholder nel caso updatedAt non sia presente
                            name="updatedAt"
                            disabled
                            readOnly
                            value={
                              formatDate(newProductionSheet.updatedAt) || ""
                            }
                          />
                        </Form.Group>
                      )}
                    </Card.Body>
                  </Card>
                </Col>
                <Col xs={12} lg={4}>
                  <InformationForm />
                </Col>
              </Row>
              <Row>
                <Col xs={12} lg={12}>
                  <AccessoriesForm />
                </Col>
              </Row>

              <Row>
                <Col xs={12} lg={12}>
                  <MaterialForm />
                </Col>
              </Row>

              <Row>
                {/* Dati busta */}
                <Col xs={12} lg={6}>
                  {/* Varie tabelle e form */}
                  <BagDetailsForm />
                  {/* <PackingDetailsFormTest /> */}
                  <PrintDetailsForm />
                </Col>

                {/* Dati Produttivi */}
                <Col xs={12} lg={6}>
                  <ProductionDetailsForm />

                  <ExtrusionDetailsForm />
                </Col>
                {/* Estrusione e stampa*/}
              </Row>

              <Row>
              <Col xs={12} lg={12}>

                  <PackingDetailsForm />
                  </Col>

                <Col xs={12} lg={12}>
                  <ResultsForm />
                </Col>
              </Row>

              <Row>
                <Col xs={12} lg={12}>
                  <Card className={"mb-5"}>
                    <Card.Header>
                      {" "}
                      <Card.Title>Costs</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <PriceTable />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>

            <Row className="mb-5">
              <Col xs={1} lg={1}></Col>
              {/* Colonna per i primi 3 pulsanti */}
              <Col xs={6} lg={6} className={`${styles.crudBlockContainer}`}>
                <div className="d-flex justify-content-between w-100">
                  {productionSheetId ? (
                    <>
                      <Button
                        variant="warning"
                        className={` ${styles.crudButtons} mb-2 flex-grow-1 mx-2`}
                        as={Link}
                        to={`/dashboard/productionSheetPage`}
                      >
                        <PlusCircle size={25} />
                        New
                      </Button>

                      <Button
                        className={` ${styles.crudButtons} mb-2 flex-grow-1 mx-2`}
                        onClick={handleCopy}
                      >
                        <Files size={25} />
                        Copy
                      </Button>

                      <Button
                        variant="warning"
                        className={` ${styles.crudButtons}  mb-2 flex-grow-1 mx-2`}
                        onClick={handleDelete}
                      >
                        <Trash3 size={25} />
                        Delete
                      </Button>
                    </>
                  ) : (
                    <>
                      <div className="flex-grow-1 mx-2"></div>
                      <div className="flex-grow-1 mx-2"></div>
                      <div className="flex-grow-1 mx-2"></div>
                    </>
                  )}
                </div>
              </Col>
              {/* Colonna per gli ultimi 2 pulsanti */}
              <Col xs={4} lg={4} className={`${styles.crudBlockContainer}`}>
                <div className="d-flex justify-content-between w-100">
                  <Button
                    variant="primary"
                    type="submit"
                    className={` ${styles.crudButtonsSave} mb-2 flex-grow-1 mx-2`}
                    onClick={submitNewProductionSheet}
                    disabled={isLoadingConfirm}
                  >
                    {isLoadingConfirm ? (
                      <>
                        <SpinnerButton size={25} />{" "}
                        <span className="me-2"> Saving...</span>
                      </>
                    ) : (
                      <>
                        <Save size={25} />
                        Save
                      </>
                    )}
                  </Button>

                  <Button
                    variant="danger"
                    className={` ${styles.crudButtonsErase} mb-2 flex-grow-1 mx-2`}
                    onClick={discardAction}
                    disabled={isLoadingConfirm}
                  >
                    <ArrowLeftCircle size={30} />
                    Back to list
                  </Button>
                </div>
              </Col>
              <Col xs={1} lg={1}></Col>
            </Row>
          </Row>
        </Form>
        {/* </Container> */}
        <DeleteModal
          show={showDeleteModal}
          handleClose={handleDeleteCloseModal}
          handleConfirm={handleConfirmDelete}
          message={`Are you sure you want to delete Production Sheet ID ${productionSheetId}?`}
          isLoading={isLoading}
        />

        <CopyModal
          show={showCopyModal}
          handleClose={handleCloseCopyModal}
          handleConfirm={handleConfirmCopy}
          message={`Are you sure you want to copy Production Sheet ID ${productionSheetId}?`}
          isLoading={isLoading}
        />
      </productionSheetContext.Provider>
    </>
  );
};

export default ProductionSheetForm;
