const calculateWeightSingleBag = (productionSheet) => {
  const widthDevelopment =
    Number(productionSheet.bagDetails.width) +
    Number(productionSheet.bagDetails.sideGussetOpen);

  const heightDevelopment =
    Number(productionSheet.bagDetails.height) +
    Number(productionSheet.bagDetails.upperFlapOpen) +
    Number(productionSheet.bagDetails.bottomGussetOpen) / 2;

  const bareWeight = (
    Number(widthDevelopment) *
    Number(heightDevelopment) *
    2 *
    (Number(productionSheet.bagDetails.thickness) / 10000) *
    Number(productionSheet.materialSpecificWeight)
  ).toFixed(2);

  // Calcolo corretto del peso netto considerando il valore di cuttingDie
  const netDieCutWeight = (
    Number(bareWeight) * (1 - Number(productionSheet.bagDetails.cuttingDie) / 100)
  ).toFixed(2);

  return { bareWeight: Number(bareWeight), netDieCutWeight: Number(netDieCutWeight) };
};

export default calculateWeightSingleBag;

