// Import react and router DOM
import React, { useContext, useEffect, useState } from "react";

//Import components

//Import states, contexts and CSS
// import styles from "./PrintDetailsForm.module.scss";
import productionSheetContext from "../../../store/productionSheetContext.jsx";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

const PrintDetailsForm = () => {
  const { newProductionSheet, setNewProductionSheet } = useContext(
    productionSheetContext
  );

  // Local state to track total colors
  const [totalColors, setTotalColors] = useState(
    Number(newProductionSheet.printing.colorsNumberTotal || 0)
  );

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    const numericValue = value ? Number(value) : 0; // Ensure numeric value

    setNewProductionSheet((prevData) => ({
      ...prevData,
      printing:{
        ...prevData.printing,
        [name]: numericValue,
      }
    }));
  };

  // Update total colors whenever front or back colors change
  useEffect(() => {
    const frontColors = Number(newProductionSheet.printing.colorsNumberFront || 0);
    const backColors = Number(newProductionSheet.printing.colorsNumberBack || 0);
    const total = frontColors + backColors;

    setTotalColors(total);

    setNewProductionSheet((prevData) => ({
      ...prevData,
      printing: {
       ...prevData.printing,
        colorsNumberTotal: total, // Update global state with the calculated total
      },
    }));
  }, [
    newProductionSheet.printing.colorsNumberFront,
    newProductionSheet.printing.colorsNumberBack,
  ]);

  return (
    <>
      <Card className={"mb-5"}>
        <Card.Header>
          <Card.Title>Print details</Card.Title>
        </Card.Header>
        <Card.Body>
          <InputGroup controlId="colorsNumberFront" className={"mb-2 d-flex"}>
            <Form.Label className={"w-50"}>Front: Colors number</Form.Label>
            <Form.Control
              className="text-end"
              type="number"
              placeholder="Front colors number"
              name="colorsNumberFront"
              value={newProductionSheet.printing.colorsNumberFront || ""}
              onChange={handleChange}
            />
            <InputGroup.Text>N</InputGroup.Text>
          </InputGroup>

          <InputGroup controlId="colorsNumberBack" className={"mb-2 d-flex"}>
            <Form.Label className={"w-50"}>Back: Colors number</Form.Label>
            <Form.Control
              className="text-end"
              type="number"
              placeholder="Back colors number"
              name="colorsNumberBack"
              value={newProductionSheet.printing.colorsNumberBack || ""}
              onChange={handleChange}
            />
            <InputGroup.Text>N</InputGroup.Text>
          </InputGroup>

          <InputGroup controlId="colorsNumberTotal" className={"mb-2 d-flex"}>
            <Form.Label className={"w-50"}>Total: Colors number</Form.Label>
            <Form.Control
              className="text-end"
              type="number"
              placeholder="Total colors number"
              name="colorsNumberTotal"
              value={totalColors}
              disabled
              readOnly
            />
            <InputGroup.Text>N</InputGroup.Text>
          </InputGroup>
        </Card.Body>
      </Card>
    </>
  );
};

export default PrintDetailsForm;
