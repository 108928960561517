import React, { useContext, useEffect, useState } from "react";
import productionSheetContext from "../../../store/productionSheetContext.jsx";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

import calculateWeightSingleBag from "../../../utils/calculateWeightSingleBag.jsx";
import calculateWeightTotals from "../../../utils/calculateWeightTotals.jsx";
import calculateProductionTotalWeights from "../../../utils/calculateProductionTotalWeights.jsx";

const ResultsForm = () => {
  const { newProductionSheet, setNewProductionSheet } = useContext(
    productionSheetContext
  );

  // Stati locali per evitare loop infinita
  const [netPlusTareWeight, setNetPlusTareWeight] = useState(0);

  const calculateTareWeightProductionTotal = () => {
    let tareWeightTotal = 0;

    // Calcola il peso totale del tare
    newProductionSheet.packing.forEach((pack) => {
      tareWeightTotal += pack.packTareWeight * pack.packQuantity;
    });

    return tareWeightTotal;
  };

  const calculateNetPlusTareWeight = (tareWeightTotal) => {
    // Calcola il net plus tare weight
    return Number(newProductionSheet.netProductionWeight) + tareWeightTotal;
  };

  useEffect(() => {
    // Esegui il calcolo solo quando `bagDetails` cambia

    const { bareWeight, netDieCutWeight } =
      calculateWeightSingleBag(newProductionSheet);

    // Calculate bare weight e net die cut weight total

    setNewProductionSheet((prevData) => ({
      ...prevData,
      // tareWeightTotal,
      // netPlusTareroductionWeight: netPlusTareWeight,
      bagWeight: {
        ...prevData.bagWeight,
        bareWeight,
        netDieCutWeight,
      },
    }));
  }, [newProductionSheet.bagDetails, newProductionSheet.materials]);

  useEffect(() => {
    const { bareWeightTotal, netDieCutWeightTotal, quantityPieces } =
      calculateWeightTotals(newProductionSheet);

    
    setNewProductionSheet((prevData) => ({
      ...prevData,
      // tareWeightTotal,
      // netPlusTareroductionWeight: netPlusTareWeight,

      quantity: {
        ...prevData.quantity,
        pieces: quantityPieces,
        weight: bareWeightTotal,
      },

      productionWeight: {
        ...prevData.productionWeight,
        bareWeightTotal,
        netDieCutWeightTotal,

      },
    }));
  }, [
    newProductionSheet.bagWeight,
    newProductionSheet.quantityUnit,
  ]);

  useEffect(() => {
    let toleranceWeight = 0;

    const {
      grossProductionWeight,
      tareWeightTotal,
      netDieCutWeightTotalPlusTareWeightTotal,
    } = calculateProductionTotalWeights(newProductionSheet);
    // Console dei 3 pesi sopra:
    
    toleranceWeight = Number(grossProductionWeight) - Number(newProductionSheet.productionWeight.bareWeightTotal)

    console.log("toleranceWeight: ", toleranceWeight);
    setNewProductionSheet((prevData) => ({
      ...prevData,

      productionWeight: {
        ...prevData.productionWeight,
        grossProductionWeight,
        tareWeightTotal,
        netDieCutWeightTotalPlusTareWeightTotal,
        toleranceWeight,
        
      },
    }));
  }, [
    newProductionSheet.reel1.grossProductionMeters,
    newProductionSheet.quantityUnit,
    newProductionSheet.productionWeight.bareWeightTotal,
    newProductionSheet.outPutToleranceUnit,
    newProductionSheet.packing,
  ]);

  return (
    <>
      <Card className={"mb-5"}>
        <Card.Header>
          <Card.Title>Results</Card.Title>
        </Card.Header>
        <Card.Body>
          <InputGroup
            controlId="bareWeight"
            className={"mb-2 d-flex align-items-center"}
          >
            <Form.Label className={"me-3 w-50"}>Product weight each</Form.Label>
            <Form.Control
              className="text-end"
              type="number"
              placeholder="Product bareWeight"
              name="bareWeight"
              value={newProductionSheet.bagWeight.bareWeight}
              disabled
              readOnly
            />
            <InputGroup.Text>Gr</InputGroup.Text>
          </InputGroup>

          <InputGroup
            controlId="netDieCutWeight"
            className={"mb-2 d-flex align-items-center"}
          >
            <Form.Label className={"me-3 w-50"}>
              Product weight each - cutting die
            </Form.Label>
            <Form.Control
              className="text-end"
              type="number"
              placeholder="Product weight net cutting die"
              name="netDieCutWeight"
              value={newProductionSheet.bagWeight.netDieCutWeight}
              disabled
              readOnly
            />
            <InputGroup.Text>Gr</InputGroup.Text>
          </InputGroup>
          <hr />
          <InputGroup
            controlId="quantity"
            className={"mb-2 d-flex align-items-center"}
          >
            <Form.Label className={"me-3 w-50"}>Quantity (pcs)</Form.Label>
            <Form.Control
              className="text-end"
              type="number"
              placeholder="Quantity"
              name="quantity"
              value={newProductionSheet.quantity.pieces}
              disabled
              readOnly
            />
            <InputGroup.Text>Pcs</InputGroup.Text>
          </InputGroup>

          <hr />
          <InputGroup
            controlId="bareWeightTotal"
            className={"mb-2 d-flex align-items-center"}
          >
            <Form.Label className={"me-3 w-50"}>Bare Weight Total </Form.Label>
            <Form.Control
              className="text-end"
              type="number"
              placeholder="Bare weight total"
              name="bareWeightTotal"
              value={newProductionSheet.productionWeight.bareWeightTotal}
              disabled
              readOnly
            />
            <InputGroup.Text>Kg</InputGroup.Text>
          </InputGroup>
          <InputGroup
            controlId="toleranceWeight"
            className={"mb-2 d-flex align-items-center"}
          >
            <Form.Label className={"me-3 w-50"}>Tolerance weight</Form.Label>
            <Form.Control
              className="text-end"
              type="number"
              placeholder="Tolerance weight"
              name="toleranceWeight"
              value={newProductionSheet.productionWeight.toleranceWeight}
              disabled
              readOnly
            />
            <InputGroup.Text>Kg</InputGroup.Text>
          </InputGroup>

          <InputGroup
            controlId="grossProductionWeight"
            className={"mb-2 d-flex align-items-center"}
          >
            <Form.Label className={"me-3 w-50"}>
              Gross production weight (Bare weight + Tolerance)
            </Form.Label>
            <Form.Control
              className="text-end"
              type="number"
              placeholder="Gross production weight"
              name="grossProductionWeight"
              value={newProductionSheet.productionWeight.grossProductionWeight}
              disabled
              readOnly
            />
            <InputGroup.Text>Kg</InputGroup.Text>
          </InputGroup>

          <hr />
          <InputGroup
            controlId="netDieCutWeightTotal"
            className={"mb-2 d-flex align-items-center"}
          >
            <Form.Label className={"me-3 w-50"}>
              Net Die Cut Weight Total{" "}
            </Form.Label>
            <Form.Control
              className="text-end"
              type="number"
              placeholder="Net Die Cut weight total"
              name="netDieCutWeightTotal"
              value={newProductionSheet.productionWeight.netDieCutWeightTotal}
              disabled
              readOnly
            />
            <InputGroup.Text>Kg</InputGroup.Text>
          </InputGroup>
          <InputGroup
            controlId="tareWeightTotal"
            className={"mb-2 d-flex align-items-center"}
          >
            <Form.Label className={"me-3 w-50"}>Tare weight total</Form.Label>
            <Form.Control
              className="text-end"
              type="number"
              placeholder="Tare weight total"
              name="tareWeightTotal"
              value={newProductionSheet.productionWeight.tareWeightTotal}
              disabled
              readOnly
            />
            <InputGroup.Text>Kg</InputGroup.Text>
          </InputGroup>

          <InputGroup
            controlId="tareWeightTotal"
            className={"mb-2 d-flex align-items-center"}
          >
            <Form.Label className={"me-3 w-50"}>
              Net Die Cut Weight Total + Tare weight total
            </Form.Label>
            <Form.Control
              className="text-end"
              type="number"
              placeholder="Net die cut weight + tare weight total"
              name="tareWeightTotal"
              value={newProductionSheet.productionWeight.netDieCutWeightTotalPlusTareWeightTotal}
              disabled
              readOnly
            />
            <InputGroup.Text>Kg</InputGroup.Text>
          </InputGroup>
        </Card.Body>
      </Card>
    </>
  );
};

export default ResultsForm;
