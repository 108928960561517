// Import react
import React from "react";

// Import states, contexts, and CSS
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ArrowLeftCircle } from "react-bootstrap-icons";

const MaterialsModal = (props) => {

  // Console.log of all elements
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.title}{" "}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.component}</Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={props.onHide}>
          <ArrowLeftCircle size={20} className="me-1" />
          Back to list
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MaterialsModal;
