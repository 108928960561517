import React from "react";
import NavBar from "../navbar/NavBar";

const PrivateRouteLayout = ({ children }) => {
  return (
    <div>

      <NavBar/>

      {children}

    </div>
  );
};

export default PrivateRouteLayout;
