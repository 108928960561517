
const calculateWeightTotals = (productionSheet) => {
  const bareWeight = Number(productionSheet.bagWeight.bareWeight) || 0;
  const netDieCutWeight = Number(productionSheet.bagWeight.netDieCutWeight) || 0;
  const cuttingDie = Number(productionSheet.bagDetails.cuttingDie) || 0;

  let bareWeightTotal = 0;
  let netDieCutWeightTotal = 0;
  let quantityPieces = 0;

  if (productionSheet.quantityUnit.pcs) {
    bareWeightTotal = (bareWeight * Number(productionSheet.quantityUnit.pcs)) / 1000;
    netDieCutWeightTotal = (netDieCutWeight * Number(productionSheet.quantityUnit.pcs)) / 1000;

    quantityPieces = Number(productionSheet.quantityUnit.pcs);
  } else if (productionSheet.quantityUnit.kg) {
    bareWeightTotal = Number(productionSheet.quantityUnit.kg) / (1 - cuttingDie / 100);
    netDieCutWeightTotal = Number(productionSheet.quantityUnit.kg);
    quantityPieces = (bareWeightTotal * 1000) / bareWeight;
  }

  return {
    bareWeightTotal: Number(bareWeightTotal.toFixed(0)) || 0,
    netDieCutWeightTotal: Number(netDieCutWeightTotal.toFixed(0)) || 0,
    quantityPieces: Number(quantityPieces.toFixed(0)) || 0,
  };
};

export default calculateWeightTotals;
