import React from "react";
import { Card, Form, Table, Row, Col } from "react-bootstrap";
import { InputGroup } from "react-bootstrap";

const AccessoriesForm = () => {
  const accessories = [
    {
      id: 1,
      name: "zipAcc",
      value: 0,
      unit: "grams per meter",
      unitWeight: 0,
      weight: 0,
      total: 0,
    },
    {
      id: 2,
      name: "adhesiveAcc",
      value: 0,
      unit: "grams per meter",
      unitWeight: 0,
      weight: 0,
      total: 0,
    },
    {
      id: 3,
      name: "zipLockAcc",
      value: 0,
      unit: "grams per meter + ziplock",
      unitWeight: 0,
      weight: 0,
      total: 0,
    },
    {
      id: 4,
      name: "hookAcc",
      value: 0,
      unit: "grams each",
      unitWeight: 0,
      weight: 0,
      total: 0,
    },
    {
      id: 1,
      name: "handleAcc",
      value: 0,
      unit: "grams each",
      unitWeight: 0,
      weight: 0,
      total: 0,
    },
  ];

  return (
    <>
      <Card className="mb-5">
        <Card.Header>
          <Card.Title>Accessories</Card.Title>
        </Card.Header>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Value</th>
              <th>Unit Weight</th>
              <th>Unit</th>
              <th>Weight</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {accessories.map((acc, index) => (
              <tr key={acc.id}>
                <td>{index + 1}</td>
                <td>{acc.name}</td>
                <td>{acc.value}</td>
                <td>{acc.unitWeight}</td>
                <td>{acc.unit}</td>
                <td>{acc.weight}</td>
                <td>{acc.total}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Card.Body>
  <Row className="mb-3">
    <Col xs={4}>
      <Form.Group controlId="zipAccId">
        <Form.Label>Zip weight (grams per meter)</Form.Label>
        <InputGroup>
          <Form.Control type="number" placeholder="grams per meter" />
        </InputGroup>
      </Form.Group>
    </Col>
    <Col xs={4}>
      <Form.Group>
        <Form.Label>Total meters</Form.Label>
        <Form.Control type="number" placeholder="total meters" readOnly disabled />
      </Form.Group>
    </Col>
    <Col xs={4}>
      <Form.Group>
        <Form.Label>Total weight</Form.Label>
        <Form.Control type="number" placeholder="total weight" readOnly disabled />
      </Form.Group>
    </Col>
  </Row>

  <Row className="mb-3">
    <Col xs={4}>
      <Form.Group controlId="adhesiveAccId">
        <Form.Label>Adhesive weight (grams per meter)</Form.Label>
        <InputGroup>
          <Form.Control type="number" placeholder="grams per meter" />
        </InputGroup>
      </Form.Group>
    </Col>
    <Col xs={4}>
      <Form.Group>
        <Form.Label>Total meters</Form.Label>
        <Form.Control type="number" placeholder="total meters" readOnly disabled />
      </Form.Group>
    </Col>
    <Col xs={4}>
      <Form.Group>
        <Form.Label>Total weight</Form.Label>
        <Form.Control type="number" placeholder="total weight" readOnly disabled />
      </Form.Group>
    </Col>
  </Row>

  <Row className="mb-3">
    <Col xs={4}>
      <Form.Group controlId="zipLockAccId">
        <Form.Label>Zip lock weight (grams per meter + zip lock)</Form.Label>
        <InputGroup>
          <Form.Control type="number" placeholder="grams per meter" />
          <Form.Control type="number" placeholder="grams each lock" />
        </InputGroup>
      </Form.Group>
    </Col>
    <Col xs={4}>
      <Form.Group>
        <Form.Label>Total meters</Form.Label>
        <Form.Control type="number" placeholder="total meters" readOnly disabled />
      </Form.Group>
    </Col>
    <Col xs={4}>
      <Form.Group>
        <Form.Label>Total weight</Form.Label>
        <Form.Control type="number" placeholder="total weight" readOnly disabled />
      </Form.Group>
    </Col>
  </Row>

  <Row className="mb-3">
    <Col xs={6}>
      <Form.Group controlId="handleAccId">
        <Form.Label>Handle weight (grams each)</Form.Label>
        <InputGroup>
          <Form.Control type="number" placeholder="grams each" />
        </InputGroup>
      </Form.Group>
    </Col>
    <Col xs={6}>
      <Form.Group>
        <Form.Label>Total weight</Form.Label>
        <Form.Control type="number" placeholder="total weight" readOnly disabled />
      </Form.Group>
    </Col>
  </Row>

  <Row className="mb-3">
    <Col xs={6}>
      <Form.Group controlId="hookAccId">
        <Form.Label>Hook weight (grams each)</Form.Label>
        <InputGroup>
          <Form.Control type="number" placeholder="grams each" />
        </InputGroup>
      </Form.Group>
    </Col>
    <Col xs={6}>
      <Form.Group>
        <Form.Label>Total weight</Form.Label>
        <Form.Control type="number" placeholder="total weight" readOnly disabled />
      </Form.Group>
    </Col>
  </Row>
</Card.Body>

      </Card>
    </>
  );
};

export default AccessoriesForm;
