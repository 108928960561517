// Import react
import React, { useState, useEffect } from "react";

// Import states, contexts, and CSS
import { useActiveUser } from "../../store/activeUserContext";

import { Row, Col, Form, Button } from "react-bootstrap";
import { XCircle } from "react-bootstrap-icons";

// Import utils and functions
import getMaterials from "../../utils/getMaterials";

const NewMixtureRow = ({
  rowIndex,
  setNewMixture,
  newMixture,
  separateMaterialsAndMixtures,
  update,
}) => {
  // Get user data from context
  const { activeUser } = useActiveUser();
  
  // Get variables from local storage
  const userId = activeUser._id;
  const token = localStorage.getItem("token");


  // Variables for
  const [materialList, setMaterialList] = useState([]);
  const [filter, setFilter] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // Handle changes on input fields for single row of mixture function
  const handleInputChangeMixture = (e, rowIndex = null) => {
    const { name, value } = e.target;
    console.log("name:", name);
    console.log("value:", value);
  
    setNewMixture((prevMixture) => {
      const updatedComposition = prevMixture.composition.map((comp, index) =>
        index === rowIndex
          ? name === "filter"
            ? { material: "", percentage: 0, specificWeight: "" }
            : { ...comp, [name]: Number(value) }
          : comp
      );
  
      const updatedMixture = {
        ...prevMixture,
        composition: updatedComposition,
      };
  
      return updatedMixture;
    });
  };
  

  // Fetch of materials with debounce
  useEffect(() => {
    setIsLoading(true);
    const fetchMaterials = async () => {
      try {
        const data = await getMaterials(filter, userId, token); // Passa il filtro corrente
        const { materialsData, mixtureData } =
          separateMaterialsAndMixtures(data);

        setMaterialList(materialsData);
      } catch (error) {
        console.error("Error fetching materials:", error);
      } finally {
        setIsLoading(false);
      }
    };

    const timer = setTimeout(() => {
      fetchMaterials(); // Run fetch without filter
    }, 1000); // Debounce: 1 second

    return () => clearTimeout(timer); // Clean up timer when component unmounts
  }, [filter, update]); // Run the useffect each time filter changes

  // Function: delete single row
  const handleRemoveRow = (indexToRemove) => {
    setNewMixture((prevMixture) => ({
      ...prevMixture,
      composition: prevMixture.composition.filter(
        (_, index) => index !== indexToRemove
      ),
    }));
  };

  // Function: handle select material
  const handleSelectMaterial = (e, rowIndex) => {
    const { value } = e.target;

    setNewMixture((prevMixture) => {
      const updatedMixture = { ...prevMixture };
      const selectedMaterial = materialList.find(
        (material) => material.name === value
      );

      if (selectedMaterial) {
        updatedMixture.composition[rowIndex] = {
          ...updatedMixture.composition[rowIndex],
          material: selectedMaterial.name,
          specificWeight: selectedMaterial.composition[0]?.specificWeight || "",
        };
      }

      return updatedMixture;
    });

    setFilter("");
  };

  return (
    <div>
      <hr />
      <Row className="mb-2">
        <Col xs={12} lg={3}>
          {rowIndex === 0 && <Form.Label>Filter</Form.Label>}
          <Form.Control
            name="filter"
            type="text"
            placeholder="Filter material"
            value={filter}
            onChange={(e) => {
              setFilter(e.target.value);
              handleInputChangeMixture(e, rowIndex);
            }}
          />
        </Col>
        <Col xs={12} lg={4}>
          {rowIndex === 0 && <Form.Label>Material</Form.Label>}

          <Form.Select
            placeholder="Select Material"
            name="material"
            value={newMixture.composition[rowIndex]?.material || ""}
            onChange={(e) => handleSelectMaterial(e, rowIndex)}
            disabled={isLoading}
          >
            {isLoading ? (
              <option disabled>Loading materials...</option>
            ) : materialList.length === 0 ? (
              <option value="" disabled>
                No materials found
              </option>
            ) : (
              <>
                <option value="" disabled>
                  Select a material
                </option>
                {materialList.map((material) => (
                  <option key={material.id} value={material.name}>
                    {`${material.name} ${
                      material.composition[0]?.percentage || 0
                    }% - ${material.brand} - Specific Weight: ${
                      material.composition[0]?.specificWeight || ""
                    }`}
                  </option>
                ))}
              </>
            )}
          </Form.Select>
        </Col>
        <Col xs={12} lg={2}>
          {rowIndex === 0 && <Form.Label>Specific weight</Form.Label>}
          <Form.Control
            type="number"
            name="specificWeight"
            disabled
            readOnly
            value={newMixture.composition[rowIndex]?.specificWeight || ""}
          />
        </Col>
        <Col xs={12} lg={2}>
          {rowIndex === 0 && <Form.Label>Percentage</Form.Label>}
          <Form.Control
            type="number"
            name="percentage"
            min={0.1}
            max={99.9}
            value={newMixture.composition[rowIndex]?.percentage || 0}
            onChange={(e) => handleInputChangeMixture(e, rowIndex)}
          />
        </Col>
        <Col xs={12} lg={1}>
          {rowIndex === 0 && <Form.Label> Delete</Form.Label>}
          <Button variant="danger" onClick={() => handleRemoveRow(rowIndex)}>
            <XCircle />
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default NewMixtureRow;
