// Import react
import React, { useState, useEffect } from "react";

// Import states, contexts, and CSS
import { useActiveUser } from "../../store/activeUserContext";

import { Button, Form, Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import styles from "./profileData.module.scss";

// Import third party libraries
import countryList from "react-select-country-list";

const ProfileData = () => {
  // Get variables from local storage
  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("userId");

  // Get user data from context
  const { activeUser, setActiveUser } = useActiveUser();

  // Variable in order to refresh user data
  const [update, setUpdate] = useState(false);
  
  // Input Variables and states
  const [profileData, setProfileData] = useState({});
  const [selectedField, setSelectedField] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [newValue, setNewValue] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const countryOptions = countryList().getData();

  // Variables for modal
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState("");


 // Fetch user data when component mounts or when token or userId changes
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/api/users/${userId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Errore nella connessione al server");
        }

        const user = await response.json();
        setProfileData(user);

        setActiveUser(user);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [token, userId, update]);

  // HHandle input changes
  const handleEditClick = (field, e) => {
    e.preventDefault();
    const title = e.target.name;
    setModalTitle(title);
    setModalShow(true);
    setSelectedField(field);
    setSelectedValue(profileData[field]);
    setNewValue(""); // Reset del nuovo valore
  };

  // andle form submission
  const handleConfirmEdit = async () => {
    try {
      if (selectedField === "password") {
        // Verifica la conferma della nuova password
        if (newValue !== confirmNewPassword) {
          alert("Le due password non coincidono.");
          return;
        }

        if (!oldPassword) {
          alert("Inserire la password attuale.");
          return;
        }
      }

      const body =
        selectedField === "password"
          ? { currentPassword: oldPassword, password: newValue } // Per modifica password
          : { currentPassword: oldPassword, [selectedField]: newValue }; // Per modifica di altri campi

      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/users/${userId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(body),
        }
      );

      if (!response.ok) {
        throw new Error("Errore durante l'aggiornamento del campo.");
      }

      alert("Modifica completata con successo!");
      // Aggiorna i dati del profilo
      const updatedProfile = await response.json();
      setProfileData(updatedProfile);

      // Resetta gli stati
      setSelectedField("");
      setSelectedValue("");
      setNewValue("");
      setOldPassword("");
      setConfirmNewPassword("");
      setUpdate((prevUpdate) => !prevUpdate); // Aggiorna lo stato per mostrare le modifiche
      setModalShow(false);
    } catch (error) {
      console.log(error);
      alert("Errore durante la modifica.");
    }
  };

  return (
    <>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
        onHide={() => setModalShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {modalTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedField === "firstName" || selectedField === "lastName" ? (
            <Form.Group controlId="newValue">
              <Form.Label>New value</Form.Label>
              <Form.Control
                type="text"
                placeholder="New value"
                value={newValue}
                onChange={(e) => setNewValue(e.target.value)}
              />
            </Form.Group>
          ) : (
            <></>
          )}

          {selectedField === "email" ? (
            <Form.Group controlId="newEmail">
              <Form.Label>New email</Form.Label>
              <Form.Control
                type="email"
                placeholder="New email"
                value={newValue}
                onChange={(e) => setNewValue(e.target.value)}
              />
            </Form.Group>
          ) : (
            <></>
          )}

          {selectedField === "dateOfBirth" ? (
            <Form.Group controlId="newDateOfBirth">
              <Form.Label>New date of birth</Form.Label>
              <Form.Control
                type="date"
                placeholder="New date of birth"
                value={newValue}
                onChange={(e) => setNewValue(e.target.value)}
              />
            </Form.Group>
          ) : (
            <></>
          )}

          {selectedField === "countryOfResidence" ? (
            <Form.Group controlId="newCountryOfResidence">
              <Form.Label>New country of residence</Form.Label>
              <Form.Select
                placeholder="New country of residence"
                value={newValue}
                onChange={(e) => setNewValue(e.target.value)}
              >
                <option value="">Select a country of residence</option>
                {countryOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          ) : (
            <></>
          )}

          {selectedField === "password" ? (
            <>
              <Form.Group controlId="newPassword">
                <Form.Label>New password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="New password"
                  value={newValue}
                  onChange={(e) => setNewValue(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="confirmNewPassword">
                <Form.Label>Confirm New password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Confirm new password"
                  value={confirmNewPassword}
                  onChange={(e) => setConfirmNewPassword(e.target.value)}
                  disabled={newValue ? false : true}
                />
              </Form.Group>
            </>
          ) : (
            <></>
          )}

          <hr />
          <Form.Group controlId="password">
            <Form.Label>Current password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter your current password"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              disabled={newValue ? false : true}
            />
          </Form.Group>

          <hr />
          <Button
            variant="success"
            onClick={handleConfirmEdit}
            disabled={!selectedField || !newValue || !oldPassword}
          >
            Confirm Edit
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              setSelectedField("");
              setSelectedValue("");
              setNewValue("");
              setModalShow(false);
            }}
          >
            Back to profile
          </Button>
        </Modal.Body>
      </Modal>
      <Row>
        <Col xs={12}>
          <div className={"mt-5 mb-5"}>
            <Table striped bordered hover>
              <tbody>
                <tr>
                  <th scope="row">First Name</th>
                  <td>{profileData.firstName}</td>
                  <td>
                    <Button
                      name="First name"
                      variant="primary"
                      onClick={(e) => handleEditClick("firstName", e)}
                    >
                      Edit
                    </Button>
                  </td>
                </tr>
                <tr>
                  <th scope="row">Last Name</th>
                  <td>{profileData.lastName}</td>
                  <td>
                    <Button
                      name="Last name"
                      variant="primary"
                      onClick={(e) => handleEditClick("lastName", e)}
                    >
                      Edit
                    </Button>
                  </td>
                </tr>
                <tr>
                  <th scope="row">Email</th>
                  <td>{profileData.email}</td>
                  <td>
                    <Button
                      name="Email"
                      variant="primary"
                      onClick={(e) => handleEditClick("email", e)}
                    >
                      Edit
                    </Button>
                  </td>
                </tr>
                <tr>
                  <th scope="row">Date of Birth</th>
                  <td>
                    {profileData.dateOfBirth
                      ? new Date(profileData.dateOfBirth).toLocaleDateString(
                          "it-IT"
                        )
                      : "N/A"}
                  </td>
                  <td>
                    <Button
                      name="Date of birth"
                      variant="primary"
                      onClick={(e) => handleEditClick("dateOfBirth", e)}
                    >
                      Edit
                    </Button>
                  </td>
                </tr>
                <tr>
                  <th scope="row">Country Of Residence</th>
                  <td>{profileData.countryOfResidence}</td>
                  <td>
                    <Button
                      name="Country of residence"
                      variant="primary"
                      onClick={(e) => handleEditClick("countryOfResidence", e)}
                    >
                      Edit
                    </Button>
                  </td>
                </tr>
                <tr>
                  <th scope="row">Password</th>
                  <td>************</td>
                  <td>
                    <Button
                      name="Password"
                      variant="primary"
                      onClick={(e) => handleEditClick("password", e)}
                    >
                      Edit
                    </Button>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ProfileData;
