// Import react and router DOM
import React, { useContext, useEffect, useState } from "react";

//Import components

//Import states, contexts and CSS
import { Card, Form } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import productionSheetContext from "../../../store/productionSheetContext.jsx";

// Import utils and functions
import calculateReelBandWithAddition from "../../../utils/calculateReelBandWithAddition.jsx";
import calculateMeterWeightRatio from "../../../utils/calculateMeterWeightRatio.jsx";
import calculateNetProductionMeters from "../../../utils/calculateNetProductionMeters.jsx";
import calculateGrossProductionMeters from "../../../utils/calculateGrossProductionMeter.jsx";

const ExtrusionDetailsForm = () => {
  const { newProductionSheet, setNewProductionSheet } = useContext(
    productionSheetContext
  );

  const [reel1HasAddition, setReel1HasAddition] = useState(false);
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    const reelRef = e.target.getAttribute("reelref");

    setNewProductionSheet((prevData) => ({
      ...prevData,
      [reelRef]: {
        ...prevData[reelRef], // Mantiene gli altri valori di reel1 o reel2
        [name]: name === "tracksDirection" ? parseInt(value, 10) : value,
      },
    }));
  };


  const handleChangeReelType = (e) => {
    const selectedOption = e.target.value;
    const reelRef = e.target.getAttribute("reelref");


    setNewProductionSheet((prevData) => ({
      ...prevData,
      [reelRef]: { ...prevData[reelRef], reelType: selectedOption },
    }));
  };


  // Calcolo di tutti i dati di reel1
  useEffect(() => {
    // Aggiorna la banda di rete e la banda di rete aggiunta quando i campi cambiano
    const reel1Band = calculateReelBandWithAddition(
      newProductionSheet,
      "reel1"
    );
    const meterWeightRatio1 = calculateMeterWeightRatio(
      newProductionSheet,
      "reel1",
      reel1Band
    );
    const netProductionMeters1 = calculateNetProductionMeters(
      newProductionSheet,
      "reel1"
    );

     setReel1HasAddition(newProductionSheet.reel1.rightReelAddition > 0 || newProductionSheet.reel1.leftReelAddition > 0);
    const grossProductionMeters1 = calculateGrossProductionMeters(newProductionSheet, "reel1")

    setNewProductionSheet((prevData) => ({
      ...prevData,
      reel1: {
        ...prevData.reel1,
        reelBand: reel1Band,
        meterWeightRatio: meterWeightRatio1,
        netProductionMeters: netProductionMeters1,
        grossProductionMeters: grossProductionMeters1,
      },
    }));
  }, [
    newProductionSheet.bagDetails,
    newProductionSheet.reel1.reelType,
    newProductionSheet.reel1.tracksDirection,
    newProductionSheet.reel1.rightReelAddition,
    newProductionSheet.reel1.leftReelAddition,

    newProductionSheet.reel1.tracksNumber,
    newProductionSheet.outputToleranceUnit,
    newProductionSheet.quantity
  ]);

  // Calcolo di tutti i dati di reel2
  useEffect(() => {
    // Aggiorna la banda di rete e la banda di rete aggiunta quando i campi cambiano
    const reel2Band = calculateReelBandWithAddition(
      newProductionSheet,
      "reel2"
    );
    const meterWeightRatio1 = calculateMeterWeightRatio(
      newProductionSheet,
      "reel2",
      reel2Band
    );
    const netProductionMeters1 = calculateNetProductionMeters(
      newProductionSheet,
      "reel2"
    );

    const grossProductionMeters1 = calculateGrossProductionMeters(newProductionSheet, "reel2")

    

    setNewProductionSheet((prevData) => ({
      ...prevData,
      reel2: {
        ...prevData.reel2,
        reelBand: reel2Band,
        meterWeightRatio: meterWeightRatio1,
        netProductionMeters: netProductionMeters1,
        grossProductionMeters: grossProductionMeters1,
      },
    }));
  }, [
    newProductionSheet.bagDetails,
    newProductionSheet.reel2.reelType,
    newProductionSheet.reel2.tracksDirection,
    newProductionSheet.reel2.rightReelAddition,
    newProductionSheet.reel2.leftReelAddition,
    newProductionSheet.reel2.tracksNumber,
    newProductionSheet.outputToleranceUnit,
    newProductionSheet.quantity
  ]);



  return (
    <>
      <Card className={"mb-5"}>
        <Card.Header>
          <Card.Title>Reel 1</Card.Title>
        </Card.Header>
        <Card.Body>
          <InputGroup controlId="reelType1" className={"mb-2 d-flex"}>
            <Form.Label className={"w-50"}>Extrusion type</Form.Label>
            <Form.Select
              onChange={handleChangeReelType}
              value={newProductionSheet.reel1.reelType}
              reelRef="reel1"
            >
              <option value={"Tubular"}>Tubular</option>
              <option value={"Single Fold"}>Single Fold</option>
              <option value={"Flat"}>Flat</option>
              <option value={"Gusseted Tubular"}>Gusseted Tubular </option>
              <option value={"Gusseted Single Fold"}>
                Gusseted Single Fold
              </option>
            </Form.Select>
          </InputGroup>

          <InputGroup controlId="tracksNumberReel1" className={"mb-2 d-flex"}>
            <Form.Label className={"w-50"}>Tracks number</Form.Label>
            <Form.Control
              reelRef="reel1"
              className="text-end"
              type="number"
              placeholder="Tracks number"
              name="tracksNumber"
              value={newProductionSheet.reel1.tracksNumber}
              onChange={handleChange}
            />
            <InputGroup.Text>N</InputGroup.Text>
          </InputGroup>

          <InputGroup
            controlId="tracksDirectionReel1"
            className={"mb-2 d-flex align-items-center"}
          >
            <Form.Label className={"w-50"}>Tracks direction</Form.Label>
            <Form.Select
              reelRef="reel1"
              aria-label="tracksDirection"
              onChange={handleChange}
              name="tracksDirection"
              value={newProductionSheet.reel1.tracksDirection}
            >
              <option value="1">Vertical</option>
              <option value="2">Horizontal</option>
            </Form.Select>
          </InputGroup>

          <InputGroup
            controlId="rightReelAdditionReel1"
            className={"mb-2 d-flex"}
          >
            <Form.Label className={"w-50"}>Right reel addition</Form.Label>
            <Form.Control
              reelRef="reel1"
              className="text-end"
              type="number"
              placeholder="Right reel addition"
              name="rightReelAddition"
              value={newProductionSheet.reel1.rightReelAddition}
              onChange={handleChange}
            />
            <InputGroup.Text>Cm</InputGroup.Text>
          </InputGroup>

          <InputGroup
            controlId="leftReelAdditionReel1"
            className={"mb-2 d-flex"}
          >
            <Form.Label className={"w-50"}>Left reel addition</Form.Label>
            <Form.Control
              reelRef="reel1"
              className="text-end"
              type="number"
              placeholder="Left reel addition"
              name="leftReelAddition"
              value={newProductionSheet.reel1.leftReelAddition}
              onChange={handleChange}
            />
            <InputGroup.Text>Cm</InputGroup.Text>
          </InputGroup>

          <InputGroup controlId="reelBandReel1" className={"mb-2 d-flex"}>
            <Form.Label className={"w-50"}>Reel band</Form.Label>
            <Form.Control
              reelRef="reel1"
              className="text-end"
              type="number"
              placeholder="Reel band"
              name="reelBand"
              value={newProductionSheet.reel1.reelBand}
              disabled
              readOnly
            />
            <InputGroup.Text>Cm</InputGroup.Text>
          </InputGroup>

          <InputGroup
            controlId="meterWeightRatioReel1"
            className={"mb-2 d-flex"}
          >
            <Form.Label className={"w-50"}>Meter/Weight Ratio</Form.Label>
            <Form.Control
              reelRef="reel1"
              className="text-end"
              type="number"
              placeholder="Meter Weight Ratio"
              name="meterWeightRatio"
              value={newProductionSheet.reel1.meterWeightRatio}
              disabled
              readOnly
            />
            <InputGroup.Text>Gr</InputGroup.Text>
          </InputGroup>

          <InputGroup
            controlId="netProductionMetersReel1"
            className={"mb-2 d-flex"}
          >
            <Form.Label className={"w-50"}>Net Production Meters</Form.Label>
            <Form.Control
              reelRef="reel1"
              className="text-end"
              type="number"
              placeholder="Net Production Meters"
              name="netProductionMeters"
              value={newProductionSheet.reel1.netProductionMeters}
              disabled
              readOnly
            />
            <InputGroup.Text>Mtl</InputGroup.Text>
          </InputGroup>

          <InputGroup
            controlId="grossProductionMetersReel1"
            className={"mb-2 d-flex"}
          >
            <Form.Label className={"w-50"}>Gross Production Meters</Form.Label>
            <Form.Control
              reelRef="reel1"
              className="text-end"
              type="number"
              placeholder="Gross Production Meters"
              name="grossProductionMeters"
              value={newProductionSheet.reel1.grossProductionMeters}
              disabled
              readOnly
            />
            <InputGroup.Text>Mtl</InputGroup.Text>
          </InputGroup>
        </Card.Body>

{ reel1HasAddition &&        (<><Card.Header>
          <Card.Title>Reel 2</Card.Title>
        </Card.Header>
        <Card.Body>
          <InputGroup controlId="reelType2" className={"mb-2 d-flex"}>
            <Form.Label className={"w-50"}>Extrusion type</Form.Label>
            <Form.Select
              onChange={handleChangeReelType}
              value={newProductionSheet.reel2.reelType}
              reelRef="reel2"
            >
              <option value={"Tubular"}>Tubular</option>
              <option value={"Single Fold"}>Single Fold</option>
              <option value={"Flat"}>Flat</option>
              <option value={"Gusseted Tubular"}>Gusseted Tubular</option>
              <option value={"Gusseted Single Fold"}>
                Gusseted Single Fold
              </option>
            </Form.Select>
          </InputGroup>

          <InputGroup controlId="tracksNumberReel2" className={"mb-2 d-flex"}>
            <Form.Label className={"w-50"}>Tracks number</Form.Label>
            <Form.Control
              reelRef="reel2"
              className="text-end"
              type="number"
              placeholder="Tracks number"
              name="tracksNumber"
              value={newProductionSheet.reel2.tracksNumber}
              onChange={handleChange}
            />
            <InputGroup.Text>N</InputGroup.Text>
          </InputGroup>

          <InputGroup
            controlId="tracksDirectionReel2"
            className={"mb-2 d-flex align-items-center"}
          >
            <Form.Label className={"w-50"}>Tracks direction</Form.Label>
            <Form.Select
              reelRef="reel2"
              aria-label="tracksDirection"
              onChange={handleChange}
              name="tracksDirection"
              value={newProductionSheet.reel2.tracksDirection}
            >
              <option value="1">Vertical</option>
              <option value="2">Horizontal</option>
            </Form.Select>
          </InputGroup>



          

          <InputGroup controlId="reelBandReel2" className={"mb-2 d-flex"}>
            <Form.Label className={"w-50"}>Reel band</Form.Label>
            <Form.Control
              reelRef="reel2"
              className="text-end"
              type="number"
              placeholder="Reel band"
              name="reelBand"
              value={newProductionSheet.reel2.reelBand}
              disabled
              readOnly
            />
            <InputGroup.Text>Cm</InputGroup.Text>
          </InputGroup>
          <InputGroup
            controlId="meterWeightRatioReel1"
            className={"mb-2 d-flex"}
          >
            <Form.Label className={"w-50"}>Meter/Weight Ratio</Form.Label>
            <Form.Control
              reelRef="reel2"
              className="text-end"
              type="number"
              placeholder="Meter Weight Ratio"
              name="meterWeightRatio"
              value={newProductionSheet.reel2.meterWeightRatio}
              disabled
              readOnly
            />
            <InputGroup.Text>Gr</InputGroup.Text>
          </InputGroup>

          <InputGroup
            controlId="netProductionMetersreel2"
            className={"mb-2 d-flex"}
          >
            <Form.Label className={"w-50"}>Net Production Meters</Form.Label>
            <Form.Control
              reelRef="reel2"
              className="text-end"
              type="number"
              placeholder="Net Production Meters"
              name="netProductionMeters"
              value={newProductionSheet.reel2.netProductionMeters}
              disabled
              readOnly
            />
            <InputGroup.Text>Mtl</InputGroup.Text>
          </InputGroup>

          <InputGroup
            controlId="grossProductionMetersreel2"
            className={"mb-2 d-flex"}
          >
            <Form.Label className={"w-50"}>Gross Production Meters</Form.Label>
            <Form.Control
              reelRef="reel2"
              className="text-end"
              type="number"
              placeholder="Gross Production Meters"
              name="grossProductionMeters"
              value={newProductionSheet.reel2.grossProductionMeters}
              disabled
              readOnly
            />
            <InputGroup.Text>Mtl</InputGroup.Text>
          </InputGroup>
        </Card.Body>
      </>)}
      </Card>
    </>
  );
};

export default ExtrusionDetailsForm;
