const getMaterials = async (filterName = "", userId, token) => {
    try {
      const url = filterName
        ? `${process.env.REACT_APP_SERVER_URL}/api/materials/users/${userId}/materials?filter=${filterName}`
        : `${process.env.REACT_APP_SERVER_URL}/api/materials/users/${userId}/materials`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const data = await response.json();
      return data; // Restituisce i dati senza modificarli
    } catch (error) {
      console.error("Error fetching materials:", error);
      return []; // Restituisce un array vuoto in caso di errore
    }
  };

  export default getMaterials;