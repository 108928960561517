import React, { useContext, useEffect } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { Button } from "react-bootstrap";
import productionSheetContext from "../../../store/productionSheetContext.jsx";
import { XCircle } from "react-bootstrap-icons";

const PackingDetailsFormRow = ({ pack, index }) => {
  const { newProductionSheet, setNewProductionSheet } = useContext(productionSheetContext);

  const handleUnitChange = (e, index) => {
    const { name, value } = e.target;

    setNewProductionSheet((prevData) => {
      if (!prevData.packing) return prevData;

      const updatedPacking = [...prevData.packing];
      if (!updatedPacking[index]) return prevData;

      updatedPacking[index] = { ...updatedPacking[index], [name]: value };

      return { ...prevData, packing: updatedPacking };
    });
  };

  const calculatePackGrossWeight = (productionsheet, index) => {
    if (!productionsheet.packing?.[index]) return 0;

    const pack = productionsheet.packing[index];
    const quantityEachPack = Number(pack.quantityEachPack) || 0;
    const packTareWeight = Number(pack.packTareWeight) || 0;

    if (pack.packQuantityUnit === "kg") {
      return quantityEachPack + packTareWeight;
    } else if (pack.packQuantityUnit === "pcs") {
      const netDieCutWeight = Number(productionsheet.bagWeight?.netDieCutWeight) || 0;
      return (netDieCutWeight / 1000) * quantityEachPack + packTareWeight;
    }

    return 0;
  };

  const calculatePackQuantity = (productionsheet, index) => {
    if (!productionsheet.packing?.[index]) return 0;

    const pack = productionsheet.packing[index];
    const quantityEachPack = Number(pack.quantityEachPack) || 0;
    const netDieCutWeightTotal = Number(productionsheet.productionWeight?.netDieCutWeightTotal) || 0;

    let result = 0;

    if (pack.packQuantityUnit === "kg") {
      result = quantityEachPack > 0 ? netDieCutWeightTotal / quantityEachPack : 0;
    } else if (pack.packQuantityUnit === "pcs") {
      const netDieCutWeight = Number(productionsheet.bagWeight?.netDieCutWeight) || 0;
      const denominator = (netDieCutWeight / 1000) * quantityEachPack;
      result = denominator > 0 ? netDieCutWeightTotal / denominator : 0;
    }

    return Math.ceil(result);
  };

  useEffect(() => {
    if (!newProductionSheet.packing?.[index]) return;

    const packGrossWeight = calculatePackGrossWeight(newProductionSheet, index);
    const packQuantity = calculatePackQuantity(newProductionSheet, index);

    setNewProductionSheet((prevData) => {
      const updatedPacking = [...prevData.packing];
      updatedPacking[index] = { ...updatedPacking[index], packGrossWeight, packQuantity };

      return { ...prevData, packing: updatedPacking };
    });
  }, [
    newProductionSheet.packing?.[index]?.packQuantityUnit,
    newProductionSheet.packing?.[index]?.quantityEachPack,
    newProductionSheet.packing?.[index]?.packTareWeight,
  ]);

  const handleDeletePack = (index) => {
    setNewProductionSheet((prevData) => {
      const updatedPacking = [...prevData.packing];
      updatedPacking.splice(index, 1);
      return { ...prevData, packing: updatedPacking };
    });
  };

  return (
    <tr>
      <th>{index + 1}</th>

      <th>
        <InputGroup>
          <Form.Select
            name="packQuantityUnit"
            value={newProductionSheet.packing?.[index]?.packQuantityUnit || ""}
            onChange={(e) => handleUnitChange(e, index)}
          >
            <option value="kg">kg</option>
            <option value="pcs">pcs</option>
          </Form.Select>
        </InputGroup>
      </th>

      <th>
        <InputGroup>
          <Form.Control
            name="quantityEachPack"
            type="number"
            min="0"
            value={newProductionSheet.packing?.[index]?.quantityEachPack || ""}
            onChange={(e) => handleUnitChange(e, index)}
          />
        </InputGroup>
      </th>

      <th>
        <InputGroup>
          <Form.Control
            name="packTareWeight"
            type="number"
            step={0.0001}
            value={newProductionSheet.packing?.[index]?.packTareWeight || ""}
            onChange={(e) => handleUnitChange(e, index)}
          />
          <InputGroup.Text>Kg</InputGroup.Text>
        </InputGroup>
      </th>

      <th>
        <InputGroup>
          <Form.Control
            type="number"
            readOnly
            disabled
            value={newProductionSheet.packing?.[index]?.packGrossWeight || ""}
          />
          <InputGroup.Text>Kg</InputGroup.Text>
        </InputGroup>
      </th>

      <th>
        <InputGroup>
          <Form.Control
            type="number"
            readOnly
            disabled
            value={newProductionSheet.packing?.[index]?.packQuantity || ""}
          />
        </InputGroup>
      </th>

      <th>
        <Button variant="danger" onClick={() => handleDeletePack(index)}>
          <XCircle />
        </Button>
      </th>
    </tr>
  );
};

export default PackingDetailsFormRow;
