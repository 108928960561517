// Import react and router DOM

const emptyProductionSheet = {
  bagType: "",
  userId: "",
  information: {
    customer: "",
    itemCode: "",
    description: "",
  },
  bagDetails: {
    width: 10,
    height: 15,
    bottomGussetOpen: 0,
    sideGussetOpen: 0,
    upperFlapOpen: 2,
    cuttingDie: 0,
    thickness: 50,
  },
  materialSpecificWeight: 0,
  weight: 0,
  bagWeight: {
    bareWeight: 0,
    netDieCutWeight: 0,
    netDieCutWeightWithAccessories: 0,
  },

  quantity: {
    pieces: 0,
    weight: 0,
  },

  productionWeight: {
    bareWeightTotal: 0,
    toleranceWeight: 0,
    grossProductionWeight: 0,

    netDieCutWeightTotal: 0,
    tareWeightTotal: 0,
    netDieCutWeightTotalPlusTareWeightTotal: 0,


    netDieCutWeightWithAccessoriesTotal: 0,
  },
   accessories: {
    zipAcc: {
      label: "Zip", 
      value: 0, 
      unitValue: "grams per meter",  
      totalQuantity: 0, 
      unitQuantity: "meters", 
      totalWeight: 0
    },
    adhesiveAcc: {
      label: "Adhesive", 
      value: 0, 
      unitValue: "grams per meter",  
      totalQuantity: 0, 
      unitQuantity: "meters", 
      totalWeight: 0
    },
    zipLockAcc: {
      label: "Zip Lock", 
      value: 0, 
      unitValue: "grams per meter",  
      gramsEachLock: 0, 
      totalQuantity: 0, 
      unitQuantity: "meters", 
      totalWeight: 0
    },
    hookAcc: {
      label: "Hook", 
      value: 0, 
      unitValue: "grams each",  
      totalQuantity: 0, 
      unitQuantity: "bag pieces quantity", 
      totalWeight: 0
    },
    handleAcc: {
      label: "Handle", 
      value: 0, 
      unitValue: "grams each",  
      totalQuantity: 0, 
      unitQuantity: "bag pieces quantity", 
      totalWeight: 0
    },
  }
  
  ,
  quantityUnit: { pcs: "", kg: "" },
  netProductionWeight: "",
  grossProductionWeight: "",
  outputTolerance: 15,
  outputToleranceUnit: { perc: 15, pcs: "", kg: "", mtl: "" },
  toleranceWeight: "",
  reel1: {
    reelType: "Flat",
    tracksDirection: 1,
    tracksNumber: 1,
    reelBand: "",
    meterWeightRatio: "",
    netProductionMeters: "",
    netProductionMetersOneTrack: "",
    grossProductionMeters: "",
    rightReelAddition: 0,
    leftReelAddition: 0,
  },
  reel2: {
    reelType: "Flat",
    tracksDirection: 1,
    tracksNumber: 1,
    reelBand: "",
    meterWeightRatio: "",
    netProductionMeters: "",
    netProductionMetersOneTrack: "",
    grossProductionMeters: "",
    rightReelAddition: 0,
    leftReelAddition: 0,
  },
  printing: {
    colorsNumberFront: "",
    colorsNumberBack: "",
    colorsNumberTotal: "",
  },
  netPlusTareroductionWeight: 0,
  tareWeightTotal: 0,
  rightReelAddition: 0, // oggetto: reel - addition
  leftReelAddition: 0, // oggetto: reel - addition
  costs: [
    {
      cost_id: 1,
      label: "Material",
      costPrice: 1000,
      unit: "5",
      totalRow: 0,
      switchActive: true,
    },
    {
      cost_id: 2,
      label: "Extrusion",
      costPrice: 0,
      unit: "0",
      totalRow: 0,
      switchActive: false,
    },
    {
      cost_id: 3,
      label: "Printing",
      costPrice: 0,
      unit: "0",
      totalRow: 0,
      switchActive: false,
    },
    {
      cost_id: 4,
      label: "Electricity",
      costPrice: 0,
      unit: "0",
      totalRow: 0,
      switchActive: false,
    },
    {
      cost_id: 5,
      label: "Cutting",
      costPrice: 0,
      unit: "0",
      totalRow: 0,
      switchActive: false,
    },
    {
      cost_id: 6,
      label: "Transport",
      costPrice: 0,
      unit: "0",
      totalRow: 0,
      switchActive: false,
    },
  ],
  profit: { profitUnit: "1", profitAmount: 0 },
  commission: { commissionUnit: "1", commissionAmount: 0 },
  packing: [
    {
      id: 1,
      label: "Primary",
      packQuantityUnit: "kg",
      quantityEachPack: 0,
      packQuantity: 0,
      packTareWeight: 0,
      packGrossWeight: 0,
    },
    {
      id: 2,
      label: "Secondary",
      packQuantityUnit: "pcs",
      quantityEachPack: 0,
      packQuantity: 0,
      packTareWeight: 0,
      packGrossWeight: 0,
    },
  ],
  materials: [
    {
      _id: null,
      category: "",
      name: "",
      brand: "",
      type: "",
      composition: [
        {
          material: "",
          percentage: 0,
          specificWeight: 0,
        },
      ],
      ponderatedSpecificWeight: 0,
      thickness: 0,
    },
  ],
};

const emptyItemFormToAdd = {
  itemCode: "",
  description: "",
  reelType: [
    { id: 1, label: "tubular", set: true },
    { id: 2, label: "single fold", set: false },
    { id: 3, label: "flat", set: false },
    { id: 4, label: "gusseted tubular", set: false },
    { id: 5, label: "gusseted single fold", set: false },
  ],

  netProductionWeightWithTolerance: "",
  packing: [
    {
      id: 1,
      label: "primary",
      packQuantityUnit: "kg",
      quantityEachPack: 2,
      packQuantity: 0,
      packTareWeight: 2,
      packGrossWeight: 4,
    },
    {
      id: 1,
      label: "primary",
      packQuantityUnit: "kg",
      quantityEachPack: 5,
      packQuantity: 0,
      packTareWeight: 1,
      packGrossWeight: 6,
    },
  ],
};

const emptyCreateAccountForm = {
  firstName: "",
  lastName: "",
  dateOfBirth: "",
  countryOfResidence: null,
  email: "",
  password: "",
};

export { emptyProductionSheet, emptyCreateAccountForm };
