// Calcolo dello specific weight ponderato
const calculatePonderatedSpecificWeight = (materiale) => {
    if (!materiale || !materiale.composition || materiale.composition.length === 0) {
      return 0;
    }
    console.log("Composition: ", materiale.composition);
    let ponderatedSpecificWeight = 0;
  
    materiale.composition.forEach((element) => {
      ponderatedSpecificWeight += (Number(element.specificWeight) * Number(element.percentage)) / 100;
      console.log("Peso specifico ponderato: ", ponderatedSpecificWeight);
    });
  
    return Number(ponderatedSpecificWeight.toFixed(2)); // Restituisce il valore arrotondato a 2 decimali
  };

  export default calculatePonderatedSpecificWeight;