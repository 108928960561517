import getDynamicValueReelType from "./getDynamicValueReelType";

const calculateMeterWeightRatio = (productionSheet, reel, reelBand) => {
  if (!productionSheet || !productionSheet[reel] || !productionSheet.bagDetails) {
    console.error("Errore: dati mancanti in productionSheet");
    return 0; // Restituisce 0 in caso di errore
  }

  const reelData = productionSheet[reel];
  const bagDetails = productionSheet.bagDetails;
  
  const reelTypeValue = parseFloat(getDynamicValueReelType(reelData.reelType)) || 1;
  const thickness = parseFloat(bagDetails.thickness) || 0;
  const materialSpecificWeight = parseFloat(productionSheet.materialSpecificWeight) || 1;
  const bandValue = parseFloat(reelBand) || 0;

  // Calcolo corretto del rapporto metro/peso
  const meterWeightRatio = 
    bandValue * 100 * reelTypeValue * (thickness / 10000) * materialSpecificWeight;

  return Number(meterWeightRatio.toFixed(2)); // Ritorna il valore calcolato con 2 decimali
};

export default calculateMeterWeightRatio;
