// Import react and router DOM
import React, { useContext, useState, useEffect } from "react";

// Import components

// Import states, contexts and CSS
import productionSheetContext from "../../../store/productionSheetContext.jsx";
import { Card, Form, Row, Col, Button, Table } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";

import { PlusCircle, XCircle } from "react-bootstrap-icons";

import { useActiveUser } from "../../../store/activeUserContext.jsx";
import getMaterials from "../../../utils/getMaterials.jsx";

import MaterialRowForm from "./MaterialRowForm.jsx";

const MaterialForm = () => {
  const { newProductionSheet, setNewProductionSheet } = useContext(
    productionSheetContext
  );

  const [materialList, setMaterialList] = useState([]);

  const { activeUser } = useActiveUser();
  const userId = activeUser._id;
  const token = localStorage.getItem("token");

  const [totalThicknessMicron, setTotalThicknessMicron] = useState(0);
  const [ponderatedSpecificWeightSheet, setPonderatedSpecificWeightSheet] =
    useState(0);

  const [updatedMaterials, setUpdatedMaterials] = useState(false);

  useEffect(() => {
    const fetchMaterials = async () => {
      try {
        const data = await getMaterials("", userId, token);
        setMaterialList(data);
      } catch (error) {
        console.error("Errore nel fetching dei materiali:", error);
      }
    };

    fetchMaterials();
  }, [userId, token]);

  // calcolo di totalthicknessMicron e ponderatedSpecificWeightSheet
  useEffect(() => {
    let totalThickness = 0;
    let totalTemp = 0;
  
    newProductionSheet.materials.forEach((material) => {
      totalThickness += material.thickness;
      totalTemp += material.ponderatedSpecificWeight * material.thickness;
    });
  
    const newTotalThicknessMicron = totalThickness.toFixed(2);
    const newPonderatedSpecificWeightSheet = (totalTemp / totalThickness).toFixed(3);
  
  
    setTotalThicknessMicron(newTotalThicknessMicron);
    setPonderatedSpecificWeightSheet(newPonderatedSpecificWeightSheet);
  
    setNewProductionSheet((prevState) => ({
      ...prevState,
      materialSpecificWeight: Number(newPonderatedSpecificWeightSheet), // ✅ Usa il valore appena calcolato
      bagDetails: {
        ...prevState.bagDetails,
        thickness: Number(newTotalThicknessMicron), // ✅ Usa il valore appena calcolato
      },
    }));
  }, [newProductionSheet.materials]);
  

  const addLayer = () => {
    setNewProductionSheet((prevState) => ({
      ...prevState,
      materials: [
        ...prevState.materials,
        {
          _id: null,
          category: "",
          name: "",
          brand: "",
          type: "",
          composition: [
            {
              material: "",
              percentage: 0,
              specificWeight: 0,
            },
          ],
          ponderatedSpecificWeight: 0,
          thickness: 0,
        },
      ],
    }));
  };

  return (
    <>
      <Card className={"mb-5"}>
        <Card.Header>
          <Card.Title>Materials</Card.Title>
        </Card.Header>
        <Card.Body>
          <Form>
            <Row>
              <Form.Group controlId="newMaterialName" className="mb-3">
                <Row className="mb-2">
                  <>
                    {newProductionSheet.materials.map((layer, index) => (
                        <MaterialRowForm
                          key={index}
                          rowIndex={index}
                          materialId={layer._id}
                          materialList={materialList}
                        />
                    ))}
                  </>
                </Row>
              </Form.Group>
            </Row>

            <hr />
            <Row>
              <Col xs={12} lg={8}></Col>

              <Col xs={12} lg={2}>
                <Form.Control
                  name="ponderatedSpecificWeightSheet"
                  type="number"
                  placeholder="Specific Weight"
                  value={ponderatedSpecificWeightSheet}
                  disabled
                  readOnly
                />
              </Col>
              <Col xs={12} lg={1}>
                <Form.Control
                  name="totalThicknessMicron"
                  type="number"
                  placeholder="Total thickness (Microns)"
                  value={newProductionSheet.bagDetails.thickness || 0}
                  disabled
                  readOnly
                />
              </Col>
              <Col xs={12} lg={1}></Col>
            </Row>
            <hr />

            <div className="d-flex justify-content-end">
              <Button variant="success" onClick={addLayer}>
                <PlusCircle size={20} />
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default MaterialForm;
