import React, { useContext, useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { Button, Table } from "react-bootstrap";
import productionSheetContext from "../../../store/productionSheetContext.jsx";
import PackingDetailsFormRow from "./packingDetailsFormRow.jsx";

const PackingDetailsForm = () => {
  const { newProductionSheet, setNewProductionSheet } = useContext(
    productionSheetContext
  );

  // 🔹 Inizializza packingArray con i dati di newProductionSheet.packing
  const [packingArray, setPackingArray] = useState(newProductionSheet.packing || []);

  // 🔹 Sincronizza packingArray quando newProductionSheet.packing cambia
  useEffect(() => {
    setPackingArray(newProductionSheet.packing || []);
  }, [newProductionSheet.packing]);

  const addRowPack = () => {
    setNewProductionSheet((prevData) => ({
      ...prevData, // ✅ Mantiene il resto dei dati
      packing: [
        ...(prevData.packing || []), // ✅ Se packing è undefined, usa un array vuoto
        {
          reel: prevData.reel1?.reelName || "", // ✅ Evita errori se reel1 non esiste
          quantityEachPack: 0,
          packTareWeight: 0,
          packQuantityUnit: "kg",
          packGrossWeight: 0,
          packQuantity: 0,
        },
      ],
    }));
  };

  return (
    <>
      <Card className="mb-5">
        <Card.Header>
          <Card.Title>Packing details</Card.Title>
        </Card.Header>
        <Card.Body>
          <Table bordered hover>
            <thead>
              <tr>
                <th>Index</th>
                <th>Unit</th>
                <th>Quantity per packing</th>
                <th>Tare weight</th>
                <th>Gross weight</th>
                <th>Quantity</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {packingArray.map((pack, index) => (
                <PackingDetailsFormRow key={index} index={index} pack={pack} />
              ))}
            </tbody>
          </Table>
          <hr />
          <Button variant="primary" onClick={addRowPack}>
            Add Pack
          </Button>
        </Card.Body>
      </Card>
    </>
  );
};

export default PackingDetailsForm;
