// Import react and router DOM
import React, { useState, useContext, useEffect } from "react";

//Import components
import PriceTableRow from "./priceTableRow/PriceTableRow.jsx";
import ValueTable from "./valueTable/valueTable.jsx";

//Import utils and functions
import getDynamicValueCost from "../../../utils/getDynamicValueCost.jsx";
import getDynamicValueProfit from "../../../utils/getDynamicValueProfit.jsx";
import getDynamicValueCommission from "../../../utils/getDynamicValueCommission.jsx";
import calculateValues from "../../../utils/calculateValues.jsx";

//Import states, contexts and CSS
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import { PlusCircle } from "react-bootstrap-icons";
import productionSheetContext from "../../../store/productionSheetContext";

// import styles from "./PriceTable.module.scss";
import Table from "react-bootstrap/Table";

const PriceTable = () => {
  const { newProductionSheet, setNewProductionSheet } = useContext(
    productionSheetContext
  );

  // Recupera i dati dal newProductionSheet o usa usa un array wuouto come fallback
  const quantity = newProductionSheet.quantity || 0;
  const netProductionWeight = newProductionSheet.netProductionWeight || 0;
  const grossProductionWeight = newProductionSheet.grossProductionWeight || 0;

  // Cost calculation
  const costs = newProductionSheet.costs || [];
  const [newCost, setNewCost] = useState("");
  const [totalCost, setTotalCost] = useState(0);

  const [unitCost, netWeightCost, grossWeightCost] = calculateValues(
    totalCost,
    quantity,
    netProductionWeight,
    grossProductionWeight
  );

  // Profit calculation
  const [profitAmount, setProfitAmount] = useState(0);
  const [profitUnit, setProfitUnit] = useState("");
  const [totalProfit, setTotalProfit] = useState(0);

  const [unitProfit, netWeightProfit, grossWeightProfit] = calculateValues(
    totalProfit,
    quantity,
    netProductionWeight,
    grossProductionWeight
  );

  // Revenue before commissions calculation
  const [revenueBeforeCommissions, setRevenueBeforeCommissions] = useState(0);

  const [
    unitRevenueBeforeCommissions,
    netWeightRevenueBeforeCommissions,
    grossWeightRevenueBeforeCommissions,
  ] = calculateValues(
    revenueBeforeCommissions,
    quantity,
    netProductionWeight,
    grossProductionWeight
  );

  // Commission calculation
  const [commissionAmount, setCommissionAmount] = useState(0);
  const [commissionUnit, setCommissionUnit] = useState("");
  const [totalCommission, setTotalCommission] = useState(0);

  const [unitCommission, netWeightCommission, grossWeightCommission] =
    calculateValues(
      totalCommission,
      quantity,
      netProductionWeight,
      grossProductionWeight
    );

  // Total revenue calculation
  const [totalRevenue, setTotalRevenue] = useState(0);

  const [unitTotalRevenue, netWeightTotalRevenue, grossWeightTotalRevenue] =
    calculateValues(
      totalRevenue,
      quantity,
      netProductionWeight,
      grossProductionWeight
    );

  // Funzione principale per calcolare i cost, profit, revenute before commissions, commission, and total revenue
  useEffect(() => {
    // Calcolo dei costi

    if (!newProductionSheet?.costs) return;

    const activeCosts = newProductionSheet.costs.filter(
      (cost) => cost.switchActive
    );

    const totalCostSum = activeCosts.reduce((acc, cost) => {
      const dynamicValue = getDynamicValueCost(cost.unit, newProductionSheet);
      const costValue = parseFloat(cost.costPrice || 0) * (dynamicValue || 0);
      return acc + costValue;
    }, 0);

    setTotalCost(totalCostSum);

    const [unitCost, netWeightCost, grossWeightCost] = calculateValues(
      totalCost,
      quantity,
      netProductionWeight,
      grossProductionWeight
    );

    // Calcolo dei profitti

    setProfitAmount(newProductionSheet.profit.profitAmount);
    setProfitUnit(newProductionSheet.profit.profitUnit);

    const calculatedProfit = getDynamicValueProfit(
      profitUnit,
      profitAmount,
      totalCostSum
    );
    setTotalProfit(calculatedProfit);

    const [unitProfit, netWeightProfit, grossWeightProfit] = calculateValues(
      totalProfit,
      quantity,
      netProductionWeight,
      grossProductionWeight
    );

    // Calcolo delle revenute prima delle commissioni

    setRevenueBeforeCommissions(totalProfit + totalCost);

    const calculatedCommission = getDynamicValueCommission(
      commissionUnit,
      commissionAmount,
      revenueBeforeCommissions
    );

    const [
      unitRevenueBeforeCommissions,
      netWeightRevenueBeforeCommissions,
      grossWeightRevenueBeforeCommissions,
    ] = calculateValues(
      revenueBeforeCommissions,
      quantity,
      netProductionWeight,
      grossProductionWeight
    );

    // Calcolo delle commissioni

    setCommissionAmount(newProductionSheet.commission.commissionAmount);
    setCommissionUnit(newProductionSheet.commission.commissionUnit);
    setTotalCommission(calculatedCommission);

    const [unitCommission, netWeightCommission, grossWeightCommission] =
      calculateValues(
        newProductionSheet.quantityUnit,
        totalCommission,
        quantity,
        netProductionWeight,
        grossProductionWeight
      );

    // Calcolo delle revenute finale

    setTotalRevenue(totalCommission + totalProfit + totalCost);

    const [unitTotalRevenue, netWeightTotalRevenue, grossWeightTotalRevenue] =
      calculateValues(
        totalRevenue,
        quantity,
        netProductionWeight,
        grossProductionWeight
      );
  }, [
    newProductionSheet,
    quantity,
    netProductionWeight,
    grossProductionWeight,
    newProductionSheet.profit,
    newProductionSheet.quantityUnit,
    totalProfit,
    totalCost,
    totalCommission,
  ]);

  const addCostRow = () => {
    const findMissingOrLastId = (list) => {
      // Ordina l'array per ID, nel caso non sia già ordinato
      const sortedCosts = list.sort((a, b) => a.cost_id - b.cost_id);

      // Trova il primo buco o restituisce l'ultimo ID
      for (let i = 0; i < sortedCosts.length; i++) {
        if (sortedCosts[i].cost_id !== i + 1) {
          // Restituisce l'ID mancante nella sequenza
          return i + 1;
        }
      }

      // Se non ci sono buchi, restituisce il prossimo ID disponibile
      return sortedCosts.length + 1;
    };
    const newCostId = findMissingOrLastId(costs);

    // Crea un nuovo costo con valori predefiniti
    const newCostObject = {
      cost_id: newCostId,
      label: newCost,
      cost: 0,
      unit: "0",
      value: "",
      switchActive: true,
    };

    // Aggiorna newProductionSheet con la nuova riga di costo
    setNewProductionSheet((newProductionSheet) => ({
      ...newProductionSheet,
      costs: [...newProductionSheet.costs, newCostObject],
    }));

    // Resetta newCost a ""
    setNewCost("");
  };

  const handleUnitProfitChange = (event) => {
    const newProfitUnit = event.target.value;
    setProfitUnit(newProfitUnit);
    setNewProductionSheet((prevData) => ({
      ...prevData,
      profit: {
        ...prevData.profit,
        profitUnit: newProfitUnit,
      },
    }));
  };

  const handleAmountProfitChange = (event) => {
    let newProfitAmount = parseFloat(event.target.value) || 0; // Conversione diretta in numero

    // Se Margin è selezionato, limita il valore massimo a 99
    if (profitUnit === "4" && newProfitAmount > 99) {
      newProfitAmount = 99;
    }

    setProfitAmount(newProfitAmount);

    setNewProductionSheet((prevData) => ({
      ...prevData,
      profit: {
        ...prevData.profit,
        profitAmount: newProfitAmount,
      },
    }));
  };

  const handleUnitCommissionChange = (event) => {
    const newCommissionUnit = event.target.value;
    setCommissionUnit(newCommissionUnit);
    setNewProductionSheet((prevData) => ({
      ...prevData,
      commission: {
        ...prevData.commission,
        commissionUnit: newCommissionUnit,
      },
    }));
  };

  const handleAmountCommissionChange = (event) => {
    const newCommissionAmount = parseFloat(event.target.value) || 0; // Conversione diretta in numero
    setCommissionAmount(newCommissionAmount);
    setNewProductionSheet((prevData) => ({
      ...prevData,
      commission: {
        ...prevData.commission,
        commissionAmount: newCommissionAmount,
      },
    }));
  };

  useEffect(() => {
    // Se Margin è selezionato e profitAmount supera 99, correggilo automaticamente
    if (profitUnit === "4" && profitAmount > 99) {
      const correctedProfitAmount = 99;
      setProfitAmount(correctedProfitAmount);

      setNewProductionSheet((prevData) => ({
        ...prevData,
        profit: {
          ...prevData.profit,
          profitAmount: correctedProfitAmount,
        },
      }));
    }

    // Se Margin è selezionato e commissionAmount supera 99, correggilo automaticamente
    if (commissionUnit === "3" && commissionAmount > 99) {
      const correctedCommissionAmount = 99;
      setCommissionAmount(correctedCommissionAmount);

      setNewProductionSheet((prevData) => ({
        ...prevData,
        commission: {
          ...prevData.commission,
          commissionAmount: correctedCommissionAmount,
        },
      }));
    }
  }, [profitUnit, profitAmount, commissionUnit, commissionAmount]);


  return (
    <>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Active</th>
            <th>#</th>
            <th>Cost</th>
            <th>Based on</th>
            <th>Value</th>
            <th>Cost</th>
            <th>Total Cost</th>
          </tr>
        </thead>
        <tbody>
          {costs.map((cost, index) => (
            <PriceTableRow
              costLabel={cost.label}
              costName={cost.cost_id}
              key={cost.cost_id}
              index={index}
            />
          ))}
          <tr>
            <td colSpan={7}>
              <Form>
                <Form.Control
                  type="text"
                  placeholder="New Cost"
                  value={newCost}
                  onChange={(e) => setNewCost(e.target.value)}
                ></Form.Control>
              </Form>
            </td>
            <td colSpan={3}>
              <Button
                disabled={newCost === ""}
                variant="success"
                onClick={addCostRow}
              >
                <PlusCircle size={20}> </PlusCircle>
                Add Cost
              </Button>
            </td>
          </tr>
        </tbody>
      </Table>

      <Table bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Total</th>
            <th>Unit</th>
            <th>Net Weight</th>
            <th>Gross weight</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td> Costs</td>
            <ValueTable
              values={[totalCost, unitCost, netWeightCost, grossWeightCost]}
              placeholders={[
                "Total Cost",
                "Unit Cost",
                "Net Weight Cost",
                "Gross Weight Cost",
              ]}
            />
          </tr>
          <tr>
            <td>
              {" "}
              Profit
              <Form>
                <Form.Select
                  onChange={handleUnitProfitChange}
                  value={profitUnit}
                >
                  <option value="1">Select</option>
                  <option value="2">Profit Amount</option>
                  <option value="3">% Markup</option>
                  <option value="4">% Margin</option>
                </Form.Select>
                <Form.Control
                  disabled={profitUnit === "1"}
                  type="number"
                  placeholder="ProfitAmount"
                  max={profitUnit === "4" ? 99 : undefined} // Limita il valore massimo di Margin a 99
                  value={profitUnit === "1" ? 0 : profitAmount}
                  onChange={handleAmountProfitChange}
                ></Form.Control>
              </Form>
            </td>
            {profitUnit !== "1" && (
              <ValueTable
                values={[
                  totalProfit,
                  unitProfit,
                  netWeightProfit,
                  grossWeightProfit,
                ]}
                placeholders={[
                  "Total Profit",
                  "Unit Profit",
                  "Net Weight Profit",
                  "Gross Weight Profit",
                ]}
              />
            )}
          </tr>
          {commissionUnit !== "1" && (
            <tr>
              <td> Revenue before commissions</td>

              <ValueTable
                values={[
                  revenueBeforeCommissions,
                  unitRevenueBeforeCommissions,
                  netWeightRevenueBeforeCommissions,
                  grossWeightRevenueBeforeCommissions,
                ]}
                placeholders={[
                  "Unit Revenue Before Commissions",
                  "Net Weight Revenue Before Commissions",
                  "Gross Weight Revenue Before Commissions",
                ]}
              />
            </tr>
          )}
          <tr>
            <td>
              {" "}
              Commissions
              <Form>
                <Form.Select
                  onChange={handleUnitCommissionChange}
                  value={commissionUnit}
                >
                  <option value="1">Select</option>
                  <option value="2">Commission Amount</option>
                  <option value="3">% Commission</option>
                </Form.Select>
                <Form.Control
                  disabled={commissionUnit === "1"}
                  max={commissionUnit === "3" ? 99 : undefined} // Limita il valore massimo di Margin a 99
                  type="number"
                  placeholder="Commision Amount"
                  value={commissionUnit === "1" ? 0 : commissionAmount}
                  onChange={handleAmountCommissionChange}
                ></Form.Control>
              </Form>
            </td>
            {commissionUnit !== "1" && (
              <ValueTable
                values={[
                  totalCommission,
                  unitCommission,
                  netWeightCommission,
                  grossWeightCommission,
                ]}
                placeholders={[
                  "Total Commission",
                  "Unit Commission",
                  "Net Weight Commission",
                  "Gross Weight Commission",
                ]}
              />
            )}
          </tr>
          <tr>
            <td> Total Revenue</td>
            <ValueTable
              values={[
                totalRevenue,
                unitTotalRevenue,
                netWeightTotalRevenue,
                grossWeightTotalRevenue,
              ]}
              placeholders={[
                "Total Revenue",
                "Unit Revenue",
                "Net Weight Revenue",
                "Gross Weight Revenue",
              ]}
            />
          </tr>
        </tbody>
      </Table>
    </>
  );
};

export default PriceTable;
