const calculateProductionTotalWeights = (productionSheet) => {
  if (!productionSheet || typeof productionSheet !== "object") {
    return { grossProductionWeight: 0, netDieCutWeightTotalPlusTareWeightTotal: 0, tareWeightTotal: 0 };
  }

  let grossProductionWeight = 0;
  let tareWeightTotal = 0;
  let netDieCutWeightTotalPlusTareWeightTotal = 0;

  let reel1MeterWeightRatio = productionSheet.reel1?.meterWeightRatio ?? 0;
  let reel1GrossMeters = productionSheet.reel1?.grossProductionMeters ?? 0;

  if (Array.isArray(productionSheet.packing)) {
    productionSheet.packing.forEach((pack) => {

      let packTareWeight = pack?.packTareWeight ?? 0;
      let packQuantity = pack?.packQuantity ?? 0;
      tareWeightTotal += (packTareWeight * packQuantity);
    });
  }

  // Calcolo del peso lordo della produzione, arrotondato all'intero più vicino
  grossProductionWeight = Number(((reel1GrossMeters * reel1MeterWeightRatio) / 1000).toFixed(0));

  console.log("net dieCut weight total: ", productionSheet.productionWeight.netDieCutWeightTotal);
  // Correzione: somma racchiusa tra parentesi
  netDieCutWeightTotalPlusTareWeightTotal = ( (productionSheet.productionWeight.netDieCutWeightTotal ?? 0) + tareWeightTotal );


  return { 
    grossProductionWeight, 
    netDieCutWeightTotalPlusTareWeightTotal, 
    tareWeightTotal 
  };
};

export default calculateProductionTotalWeights;
