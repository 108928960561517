import getDynamicValueReelType from "./getDynamicValueReelType";
import calculateNetProductionMeters from "./calculateNetProductionMeters";

const calculateGrossProductionMeters = (productionSheet, reel) => {
  let netProductionMeters = calculateNetProductionMeters(productionSheet, reel);
  let grossProductionMeters = 0;
  const toleranceUnit = productionSheet.outputToleranceUnit;

  if (toleranceUnit.perc) {
    grossProductionMeters =
      netProductionMeters * (1 + Number(toleranceUnit.perc) / 100);
  } else if (toleranceUnit.pcs) {
    const quantityPieces = Number(productionSheet.quantity.pieces);
    const tracksNumber = Number(productionSheet[reel].tracksNumber);
    const toleranceQuantityPieces = Number(toleranceUnit.pcs);
    let additionalMeters = 0;

    if (productionSheet[reel].tracksDirection === 1) {
      const heightDevelopment =
        Number(productionSheet.bagDetails.height) +
        Number(productionSheet.bagDetails.upperFlapOpen) +
        Number(productionSheet.bagDetails.bottomGussetOpen) / 2;

      additionalMeters =
        (((toleranceQuantityPieces) * heightDevelopment) /
          100) /
        tracksNumber;
    } else {
      const widthDevelopment =
        Number(productionSheet.bagDetails.width) +
        Number(productionSheet.bagDetails.sideGussetOpen);

      additionalMeters =
        (((toleranceQuantityPieces) * widthDevelopment) /
          100) /
        tracksNumber;
    }

    grossProductionMeters = netProductionMeters + additionalMeters;
  } else if (toleranceUnit.kg) {
    const meterWeightRatio = Number(productionSheet[reel].meterWeightRatio);
    const toleranceKg = Number(toleranceUnit.kg);

    grossProductionMeters = netProductionMeters + (toleranceKg * 1000) / meterWeightRatio;
  } else if (toleranceUnit.mtl) {
    grossProductionMeters = netProductionMeters + Number(toleranceUnit.mtl);
  }

  return Math.round(grossProductionMeters); // Restituisce un numero intero
};

export default calculateGrossProductionMeters;