// Import react and router DOM
import React, { useContext } from "react";

//Import components

//Import states, contexts and CSS
import productionSheetContext from "../../../store/productionSheetContext.jsx";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

// Import utils and functions

import calculateWeightSingleBag from "../../../utils/calculateWeightSingleBag.jsx";
import DebugLine from "../../../utils/debugLine.jsx";


const BagDetailsForm = () => {
  const { newProductionSheet, setNewProductionSheet } = useContext(
    productionSheetContext
  );

  const handleChange = (e) => {
    const { name, value } = e.target;

    setNewProductionSheet((prevData) => ({
      ...prevData,

      bagDetails: {
        ...prevData.bagDetails,
        [name]: Number(value),
      },
    }));

  };

  


  return (
    <>
      <Card className={"mb-5"}>
        <Card.Header>
          {" "}
          <Card.Title>Bag details</Card.Title>
        </Card.Header>
        <Card.Body>
          <InputGroup controlId="bagType" className={"mb-2"}>
            <Form.Select aria-label="Select product type" name="bagType">
              <option>Select product type</option>
              <option value="1">Polybag</option>
              <option value="2">Paper Bag</option>
            </Form.Select>
          </InputGroup>
          <InputGroup
            controlId="width"
            className={"mb-2 d-flex align-items-center"}
          >
            <Form.Label className={"w-50"}>Width</Form.Label>
            <Form.Control
              className="text-end"
              required
              type="number"
              placeholder="Product width"
              name="width"
              value={newProductionSheet.bagDetails.width}
              onChange={handleChange}
            ></Form.Control>
            <InputGroup.Text>Cm</InputGroup.Text>
          </InputGroup>
          <InputGroup
            controlId="height"
            className={"mb-2 d-flex align-items-center"}
          >
            <Form.Label className={"w-50"}>Height</Form.Label>
            <Form.Control
              className="text-end"
              type="number"
              placeholder="Product height"
              name="height"
              value={newProductionSheet.bagDetails.height}
              onChange={handleChange}
            ></Form.Control>
            <InputGroup.Text>Cm</InputGroup.Text>
          </InputGroup>

          <InputGroup
            controlId="bottomGussetOpen"
            className={"mb-2 d-flex align-items-center"}
          >
            <Form.Label className={"w-50"}>Bottom gusset open</Form.Label>
            <Form.Control
              className="text-end"
              type="number"
              placeholder="Buttom gusset open"
              name="bottomGussetOpen"
              value={newProductionSheet.bagDetails.bottomGussetOpen}
              onChange={handleChange}
            ></Form.Control>
            <InputGroup.Text>Cm</InputGroup.Text>
          </InputGroup>

          <InputGroup
            controlId="sideGussetOpen"
            className={"mb-2 d-flex align-items-center"}
          >
            <Form.Label className={"w-50"}>Side gusset open</Form.Label>
            <Form.Control
              className="text-end"
              type="number"
              placeholder="Side gusset open"
              name="sideGussetOpen"
              value={newProductionSheet.bagDetails.sideGussetOpen}
              onChange={handleChange}
            ></Form.Control>
            <InputGroup.Text>Cm</InputGroup.Text>
          </InputGroup>
          <InputGroup
            controlId="upperFlapOpen"
            className={"mb-2 d-flex align-items-center"}
          >
            <Form.Label className={"w-50"}>Upper flap open</Form.Label>
            <Form.Control
              className="text-end"
              type="number"
              placeholder="Upper flap open"
              name="upperFlapOpen"
              value={newProductionSheet.bagDetails.upperFlapOpen}
              onChange={handleChange}
            ></Form.Control>
            <InputGroup.Text>Cm</InputGroup.Text>
          </InputGroup>

          <InputGroup
            controlId="cuttingDie"
            className={"mb-2 d-flex align-items-center"}
          >
            <Form.Label className={"w-50"}>Cutting die</Form.Label>
            <Form.Control
              className="text-end"
              type="number"
              placeholder="Cutting die"
              name="cuttingDie"
              min= {0}
              max={99}
              value={newProductionSheet.bagDetails.cuttingDie}
              onChange={handleChange}
            ></Form.Control>
            <InputGroup.Text> % </InputGroup.Text>
          </InputGroup>
          <hr />
          <InputGroup
            controlId="bareWeight"
            className={"mb-2 d-flex align-items-center"}
          >
            <Form.Label className={"me-3 w-50"}>Product weight each</Form.Label>
            <Form.Control
              className="text-end"
              type="number"
              placeholder="Product bareWeight"
              name="bareWeight"
              value={newProductionSheet.bagWeight.bareWeight}
              disabled
              readOnly
            />
            <InputGroup.Text>Gr</InputGroup.Text>
          </InputGroup>

          <InputGroup
            controlId="netDieCutWeight"
            className={"mb-2 d-flex align-items-center"}
          >
            <Form.Label className={"me-3 w-50"}>
              Product weight each - cutting die
            </Form.Label>
            <Form.Control
              className="text-end"
              type="number"
              placeholder="Product weight net cutting die"
              name="netDieCutWeight"
              value={newProductionSheet.bagWeight.netDieCutWeight}
              disabled
              readOnly
            />
            <InputGroup.Text>Gr</InputGroup.Text>
          </InputGroup>

          <hr />
          <InputGroup
            controlId="thickness"
            className={"mb-2 d-flex align-items-center"}
          >
            <Form.Label className={"w-50"}>Thickness</Form.Label>
            <Form.Control
              className="text-end"
              type="number"
              placeholder="Product thickness"
              name="thickness"
              value={newProductionSheet.bagDetails.thickness}
              readOnly
              disabled
            ></Form.Control>
            <InputGroup.Text>My</InputGroup.Text>
          </InputGroup>

          <hr />

          <InputGroup
            controlId="materialSecificWeight"
            className={"mb-2 d-flex align-items-center"}
          >
            <Form.Label className={"w-50"}>Material specific weight</Form.Label>
            <Form.Control
              className="text-end"
              type="number"
              placeholder="Material specific weight"
              name="materialSpecificWeight"
              value={newProductionSheet.materialSpecificWeight}
              onChange={handleChange}
              disabled
            ></Form.Control>
            <InputGroup.Text>{"| | |"}</InputGroup.Text>
          </InputGroup>
        </Card.Body>
      </Card>
    </>
  );
};

export default BagDetailsForm;
