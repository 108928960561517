import React, { useContext, useState, useEffect } from "react";
import { Row, Col, Form, Button, Table } from "react-bootstrap";
import { XCircle } from "react-bootstrap-icons";
import productionSheetContext from "../../../store/productionSheetContext.jsx";
import { SpinnerGeneric } from "../../../utils/spinners.jsx";

const MaterialRowForm = ({ rowIndex, materialId, materialList }) => {
  const { newProductionSheet, setNewProductionSheet } = useContext(
    productionSheetContext
  );

  const [filter, setFilter] = useState("");
  const [selectedMaterial, setSelectedMaterial] = useState(null);
  const [filteredMaterialList, setFilteredMaterialList] = useState();



  // 🔹 Imposta il materiale iniziale al montaggio
  useEffect(() => {
    setFilteredMaterialList(materialList);
    if (materialList?.length > 0) {
      const defaultMaterial =
        materialList.find((mat) => mat._id === materialId) || materialList[0];
      setSelectedMaterial(defaultMaterial);
    }
  }, [materialList, materialId]);

  // 🔹 Gestisce la selezione del materiale
  const handleMaterialChange = (e) => {
    const material = filteredMaterialList.find((mat) => mat.name === e.target.value);
    if (!material) return;

    setSelectedMaterial(material);

    setNewProductionSheet((prevData) => {
      const updatedMaterials = [...prevData.materials]; // Crea una nuova copia dell'array
      updatedMaterials[rowIndex] = {
        ...updatedMaterials[rowIndex], // Mantieni gli altri dati invariati
        _id: material._id,
        category: material.category,
        name: material.name,
        brand: material.brand,
        type: material.type,
        composition: material.composition,
        ponderatedSpecificWeight: Number(material.ponderatedSpecificWeight),
      };

      return {
        ...prevData,
        materials: updatedMaterials, // Sostituisci l'array con la nuova copia
      };
    });
    setFilter("");
    setFilteredMaterialList(materialList);
  };

  // 🔹 Gestisce la modifica dello spessore (thickness)
  const handleThicknessChange = (e) => {
    const thicknessMicron = parseFloat(e.target.value) || 0;

    setNewProductionSheet((prevData) => {
      const updatedMaterials = [...prevData.materials]; // Crea una copia dell'array
      updatedMaterials[rowIndex] = {
        ...updatedMaterials[rowIndex], // Mantieni gli altri dati invariati
        thickness: thicknessMicron, // Aggiorna solo lo spessore
      };

      return {
        ...prevData,
        materials: updatedMaterials, // Sostituisci con il nuovo array
      };
    });
  };

  // Gestione del filtro
  const handleFilterChange = (e) => {
    const value = e.target.value.toLowerCase();
    setFilter(value);
    setSelectedMaterial(null); // Azzeriamo il materiale selezionato
  
    // Filtra solo la lista della riga attuale
    setFilteredMaterialList(
      materialList.filter((mat) => mat.name.toLowerCase().includes(value))
    );
  
    // Azzera lo spessore (thickness)
    setNewProductionSheet((prevData) => {
      const updatedMaterials = [...prevData.materials]; // Copia dell'array
      updatedMaterials[rowIndex] = {
        ...updatedMaterials[rowIndex], // Mantiene gli altri dati invariati
        thickness: 0, // Azzera lo spessore
      };
  
      return {
        ...prevData,
        materials: updatedMaterials, // Sostituisce con il nuovo array
      };
    });
  };
  

  // 🔹 Gestisce l'eliminazione della riga
  const handleDeleteRow = () => {
    setNewProductionSheet((prevData) => {
      const updatedMaterials = prevData.materials.filter((_, index) => index !== rowIndex); // Crea una nuova lista senza l'elemento rimosso
  
      return {
        ...prevData,
        materials: updatedMaterials, // Sostituisci con la nuova lista
      };
    });
  
    // Se stai eliminando la prima riga, resetta il filtro
    if (rowIndex === 0) {
      setFilter(""); // Reset filtro
      setFilteredMaterialList(materialList); // Ripristina la lista completa dei materiali
    }
  };
  

  return (
    <div>
      <Row className="mb-2">
        <Col xs={12} lg={1}>
          {rowIndex === 0 && <Form.Label>Layer #</Form.Label>}
          <Form.Control type="text" readOnly disabled value={rowIndex + 1} />
        </Col>

        <Col xs={12} lg={2}>
          {rowIndex === 0 && <Form.Label>Filter</Form.Label>}
          <Form.Control
            type="text"
            placeholder="Filter material"
            value={filter}
            onChange={handleFilterChange}
          />
        </Col>

        <Col xs={12} lg={2}>
          {rowIndex === 0 && <Form.Label>Material</Form.Label>}
          <Form.Select
            name="material"
            value={selectedMaterial?.name || ""}
            onChange={handleMaterialChange}
          >
            {(filter ? filteredMaterialList : materialList).length === 0 ? (
              <option value="" disabled>
                No materials found
              </option>
            ) : (
              <>
                <option value="" disabled>
                  Select a material
                </option>
                {(filter ? filteredMaterialList : materialList).map((material) => (
                  <option key={material._id} value={material.name}>
                    {material.name}
                  </option>
                ))}
              </>
            )}
          </Form.Select>
        </Col>

        <Col xs={12} lg={3}>
          {rowIndex === 0 && <Form.Label>Composition</Form.Label>}
          {selectedMaterial?.composition?.length > 0 ? (
            <Table bordered>
              <thead>
                <tr>
                  <th>Material</th>
                  <th>Percentage</th>
                  <th>Specific Weight</th>
                </tr>
              </thead>
              <tbody>
                {selectedMaterial.composition.map((comp, index) => (
                  <tr key={index}>
                    <td>{comp.material}</td>
                    <td>{`${comp.percentage}%`}</td>
                    <td>{comp.specificWeight}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <Form.Control
              type="text"
              disabled
              readOnly
              value="No composition available"
            />
          )}
        </Col>

        <Col xs={12} lg={2}>
          {rowIndex === 0 && <Form.Label>Specific weight</Form.Label>}
          <Form.Control
            type="number"
            disabled
            readOnly
            value={selectedMaterial?.ponderatedSpecificWeight || ""}
          />
        </Col>

        <Col xs={12} lg={1}>
          {rowIndex === 0 && <Form.Label>Thickness (Microns)</Form.Label>}
          <Form.Control
            type="number"
            min={0.1}
            step={1}
            max={9999}
            value={newProductionSheet.materials[rowIndex]?.thickness || 0}
            onChange={handleThicknessChange}
      />
        </Col>

        <Col xs={12} lg={1}>
          {rowIndex === 0 && <Form.Label>Delete</Form.Label>}
          <Button variant="danger" onClick={handleDeleteRow}>
            <XCircle />
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default MaterialRowForm;
