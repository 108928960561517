import getDynamicValueReelType from "./getDynamicValueReelType";

const calculateReelBandWithAddition = (productionSheet, reel) => {
  if (!productionSheet || !productionSheet[reel] || !productionSheet.bagDetails) {
    console.error("Errore: dati mancanti in productionSheet");
    return 0; // Ritorna un valore predefinito in caso di errore
  }

  const reelData = productionSheet[reel];
  const bagDetails = productionSheet.bagDetails;

  const reelTypeValue = getDynamicValueReelType(reelData.reelType) || 1; // Default a 1 se undefined
  const multiplier = reelTypeValue === 2 ? 1 : 2; // Se reelTypeValue è 2, usa 1, altrimenti 2

  // Assicura che ogni valore numerico sia valido
  const widthDevelopment =
    (Number(bagDetails.width) || 0) + 
    (Number(bagDetails.sideGussetOpen) || 0);

  const heightDevelopment =
    (Number(bagDetails.height) || 0) +
    (Number(bagDetails.upperFlapOpen) || 0) +
    ((Number(bagDetails.bottomGussetOpen) || 0) / 2); // 👈 CORRETTO L'ORDINE DI DIVISIONE

  const tracksNumber = Number(reelData.tracksNumber) || 1;
  const rightReelAddition = Number(reelData.rightReelAddition) || 0; // 👈 CORRETTO L'ACCESSO AI DATI
  const leftReelAddition = Number(reelData.leftReelAddition) || 0;

  let reelBandWithAddition;
  if (reelData.tracksDirection === 1) {
    reelBandWithAddition = (rightReelAddition + leftReelAddition) + (widthDevelopment * tracksNumber * multiplier);
  } else {
    reelBandWithAddition = (rightReelAddition + leftReelAddition) + (heightDevelopment * tracksNumber * multiplier);
  }

  return Number(reelBandWithAddition.toFixed(1)); // 👈 ORA HA UNA SOLA CIFRA DECIMALE
};

export default calculateReelBandWithAddition;




