
const getDynamicValueCost = (string,productionSheet) => {
  switch (string) {
    case "2":
      return productionSheet.netProductionWeight;
      case "3":
      return productionSheet.grossProductionWeight;
    case "4":
      return productionSheet.quantity;
    case "5":
      return productionSheet.printing.colorsNumberFront;
    case "6":
      return productionSheet.printing.colorsNumberBack;
    case "7":
      return productionSheet.printing.colorsNumberTotal;
    case "8":
      return productionSheet.toleranceWeight;
    case "9":
      return productionSheet.reel1.netProductionMeters
    case "10":
      return productionSheet.reel1.netProductionMetersOneTrack;
    case "11":
      return productionSheet.reel1.grossProductionMeters;
    case "12":
      return productionSheet.packing[0].packQuantity;
    case "13":
      return productionSheet.packing[1].packQuantity;
    default:
      return 0;
  }
};
  
export default getDynamicValueCost;