const getDynamicValueReelType = (string) => {
  switch (string) {
    case "Tubular":
      return 2;
    case "Single Fold":
      return 2;
    case "Flat":
      return 1;
    case "Gusseted Tubular":
      return 2;
    case "Gusseted Single Fold":
      return 2;
    default:
      return 0;
  }
};

export default getDynamicValueReelType;
