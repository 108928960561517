import getDynamicValueReelType from "./getDynamicValueReelType";

const calculateNetProductionMeters = (productionSheet, reel) => {
  let netProductionMeters = 0;
  const quantityPieces = Number(productionSheet.quantity.pieces);
  const tracksNumber = Number(productionSheet[reel].tracksNumber);

  if (productionSheet[reel].tracksDirection === 1) {
    const heightDevelopment =
    Number(productionSheet.bagDetails.height) +
    Number(productionSheet.bagDetails.upperFlapOpen) +
    Number(productionSheet.bagDetails.bottomGussetOpen) / 2;

    netProductionMeters = ((quantityPieces * heightDevelopment) / 100) / tracksNumber
  } else {
    const widthDevelopment =
    Number(productionSheet.bagDetails.width) +
    Number(productionSheet.bagDetails.sideGussetOpen);

    netProductionMeters = ((quantityPieces * widthDevelopment) / 100) /tracksNumber

  }

  return Number(netProductionMeters.toFixed(0)); // Assicura che il valore sia un numero con due decimali
};

export default calculateNetProductionMeters;
