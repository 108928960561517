// Import react and router DOM
import React, { useContext, useEffect, useState } from "react";

//Import components

//Import states, contexts and CSS
// import styles from "./ProductionDetailsForm.module.scss";
import productionSheetContext from "../../../store/productionSheetContext.jsx";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import DebugLine from "../../../utils/debugLine.jsx";

const ProductionDetailsForm = () => {
  const { newProductionSheet, setNewProductionSheet } = useContext(
    productionSheetContext
  );

  // Determina l'opzione inizialmente selezionata
  const [quantitySelectedOption, setQuantitySelectedOption] = useState();
  const [toleranceSelectionOption, setToleranceSelectionOption] = useState();

  // nel caso di outputtolerance, ci sono 4 possibilità, dunque va cambiato di conseguenza
  useEffect(() => {
    const toleranceUnit = newProductionSheet.outputToleranceUnit || {};
    if (toleranceUnit.perc) setToleranceSelectionOption("perc");
    else if (toleranceUnit.pcs) setToleranceSelectionOption("pcs");
    else if (toleranceUnit.kg) setToleranceSelectionOption("kg");
    else if (toleranceUnit.mtl) setToleranceSelectionOption("mtl");
  }, [newProductionSheet._id]);

  useEffect(() => {
    if (newProductionSheet.quantityUnit) {
      setQuantitySelectedOption(
        newProductionSheet.quantityUnit.pcs ? "pcs" : "kg"
      );
    }
  }, [newProductionSheet._id]);
  

  // Gestione del cambio del radio button
  const handleQuantityRadio = (e) => {
    const quantityNewSelectedOption = e.target.value;
    setQuantitySelectedOption(quantityNewSelectedOption);


    // Resetta il valore dell'input non selezionato e aggiorna lo stato globale
    setNewProductionSheet((prev) => ({
      ...prev,
      quantityUnit: {
        ...prev.quantityUnit,
        [quantityNewSelectedOption === "pcs" ? "kg" : "pcs"]: "", // Resetta il valore opposto
      },
    }));

  };

  // Gestione del cambio dei valori di input
  const handleQuantityInputChange = (e) => {
    const { name, value } = e.target;


    // Aggiorna lo stato globale
    setNewProductionSheet((prev) => ({
      ...prev,
      quantityUnit: {
        ...prev.quantityUnit,
        [name]: value, // Aggiorna solo il campo modificato
      },
    }));
  };


  const handleToleranceRadioChange = (e) => {
    const selectedOption = e.target.value;
    setToleranceSelectionOption(selectedOption);

    // Aggiorna lo stato globale con il nuovo valore selezionato
    setNewProductionSheet((prev) => ({
      ...prev,
      outputToleranceUnit: {
        perc: selectedOption === "perc" ? "0" : "",
        pcs: selectedOption === "pcs" ? "0" : "",
        kg: selectedOption === "kg" ? "0" : "",
        mtl: selectedOption === "mtl" ? "0" : "",
      },
    }));
  };

  // Gestione del cambio di valore dell'input
  const handleToleranceInputChange = (e) => {
    const { name, value } = e.target;

    // Aggiorna il valore corrispondente nello stato globale
    setNewProductionSheet((prev) => ({
      ...prev,
      outputToleranceUnit: {
        ...prev.outputToleranceUnit,
        [name]: value,
      },
    }));
  };

  return (
    <>
      <Card className={"mb-5"}>
        <Card.Header>
          <Card.Title>Production details</Card.Title>
        </Card.Header>
        <Card.Body>
          <Form.Label className={"me-3 w-50"}>Quantity</Form.Label>

          <InputGroup
            controlId="quantity-kg-pcs"
            className={"mb-2"}
          >
            <InputGroup.Radio
              name="quantity"
              value={"pcs"}
              checked={quantitySelectedOption === "pcs"}
              onChange={handleQuantityRadio}
              className="align-self-stretch"
            ></InputGroup.Radio>

            <Form.Control
              className="text-end"
              type="number"
              placeholder="Quantity (pcs)"
              name="pcs"
              value={newProductionSheet.quantityUnit.pcs}
              onChange={handleQuantityInputChange}
              disabled={quantitySelectedOption === "kg"}
            ></Form.Control>

            <InputGroup.Text>Pcs</InputGroup.Text>
          </InputGroup>

          <InputGroup
            controlId="quantity-kg-pcs"
            className={"mb-2"}
          >
            <InputGroup.Radio
              name="quantity"
              value={"kg"}
              checked={quantitySelectedOption === "kg"}
              onChange={handleQuantityRadio}
            ></InputGroup.Radio>
            <Form.Control
              className="text-end"
              type="number"
              placeholder="Quantity (kg)"
              name="kg"
              value={newProductionSheet.quantityUnit.kg}
              onChange={handleQuantityInputChange}
              disabled={quantitySelectedOption === "pcs"}
            ></Form.Control>
            <InputGroup.Text>Kg</InputGroup.Text>
          </InputGroup>

          <hr />
          <Form.Label className={"me-3 w-50"}>Output Tolerance</Form.Label>

          {/* Percentuale */}
          <InputGroup
            controlId="tolerance-%-kg-pcs-mtl"
            className={"mb-2"}
          >
            <InputGroup.Radio
              name="tolerance"
              value={"perc"}
              checked={toleranceSelectionOption === "perc"}
              onChange={handleToleranceRadioChange}
            ></InputGroup.Radio>
            <Form.Control
              className="text-end"
              type="number"
              placeholder="Tolerance (%)"
              name="perc"
              value={newProductionSheet.outputToleranceUnit?.perc || ""}
              onChange={handleToleranceInputChange}
              disabled={toleranceSelectionOption !== "perc"}
            ></Form.Control>
            <InputGroup.Text>%</InputGroup.Text>
          </InputGroup>

          {/* Pezzi */}
          <InputGroup
            controlId="tolerance-%-kg-pcs-mtl"
            className={"mb-2"}
          >
            <InputGroup.Radio
              name="tolerance"
              value={"pcs"}
              checked={toleranceSelectionOption === "pcs"}
              onChange={handleToleranceRadioChange}
            ></InputGroup.Radio>
            <Form.Control
              className="text-end"
              type="number"
              placeholder="Tolerance (pcs)"
              name="pcs"
              value={newProductionSheet.outputToleranceUnit?.pcs || ""}
              onChange={handleToleranceInputChange}
              disabled={toleranceSelectionOption !== "pcs"}
            ></Form.Control>
            <InputGroup.Text>Pcs</InputGroup.Text>
          </InputGroup>

          {/* Kilogrammi */}
          <InputGroup
            controlId="tolerance-%-kg-pcs-mtl"
            className={"mb-2"}
          >
            <InputGroup.Radio
              name="tolerance"
              value={"kg"}
              onChange={handleToleranceRadioChange}
              checked={toleranceSelectionOption === "kg"}
            ></InputGroup.Radio>
            <Form.Control
              className="text-end"
              type="number"
              placeholder="Tolerance (Kg)"
              name="kg"
              value={newProductionSheet.outputToleranceUnit?.kg || ""}
              onChange={handleToleranceInputChange}
              disabled={toleranceSelectionOption !== "kg"}
            ></Form.Control>
            <InputGroup.Text>Kg</InputGroup.Text>
          </InputGroup>

          {/* Metri lineari */}
          <InputGroup
            controlId="tolerance-%-kg-pcs-mtl"
            className={"mb-2"}
          >
            <InputGroup.Radio
              name="tolerance"
              value={"mtl"}
              onChange={handleToleranceRadioChange}
              checked={toleranceSelectionOption === "mtl"}
            ></InputGroup.Radio>
            <Form.Control
              className="text-end"
              type="number"
              placeholder="Tolerance (Mtl)"
              name="mtl"
              value={newProductionSheet.outputToleranceUnit?.mtl || ""}
              onChange={handleToleranceInputChange}
              disabled={toleranceSelectionOption !== "mtl"}
            ></Form.Control>
            <InputGroup.Text>Mtl</InputGroup.Text>
          </InputGroup>
        </Card.Body>
      </Card>
    </>
  );
};

export default ProductionDetailsForm;